/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-11 11:57:10
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-18 11:53:13
 * @FilePath: /mediatom-web/src/config/router.config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// eslint-disable-next-line
import { UserLayout, UserLayoutForManager } from '@/layouts'
// 不同环境变量下的文件路径
const viewDirname = process.env.VUE_APP_ISMANAGER === 'true' ? 'viewsForManage' : 'views'
/**
 * 1.构建基础路由
 * @type { *[] }
 */
const constantRouterMap = [
  {
    path: '/user',
    component: process.env.VUE_APP_ISMANAGER === 'true' ? UserLayoutForManager : UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        meta: {
          title: '登录'
        },
        component: () => import(/* webpackChunkName: "user" */ `@/${viewDirname}/user/Login`)
      },
      {
        path: 'register',
        name: 'register',
        meta: {
          title: '注册'
        },
        component: () => import(/* webpackChunkName: "user" */ `@/${viewDirname}/user/Register`)
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ `@/${viewDirname}/exception/404`)
  }
]
// 通过环境变量控制注册和忘记密码路由
if (process.env.VUE_APP_ISMANAGER === 'false') {
  constantRouterMap[0].children.push(
    {
      path: 'register-result',
      name: 'registerResult',
      component: () => import(/* webpackChunkName: "user" */ `@/${viewDirname}/user/RegisterResult`)
    },
    {
      path: 'password',
      name: 'password',
      component: () => import(/* webpackChunkName: "user" */ `@/${viewDirname}/user/password`)
    },
    {
      path: 'recover',
      name: 'recover',
      component: undefined
    }
  )
} else {
  constantRouterMap[0].children.push({
    path: 'recover',
    name: 'recover',
    component: undefined
  })
}

export { constantRouterMap }
