/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-07 19:02:55
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-25 15:30:57
 * @FilePath: /mediatom-web/src/init.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// with polyfills
// babel
import 'core-js/stable'
// babel functions
import 'regenerator-runtime/runtime'
import './permission' // permission control
// style
import './global.less' // global style
// adx样式
if (process.env.VUE_APP_ISMANAGER === 'true') {
  require('@/assets/less/themeForManager/index.less')
} else {
  require('./assets/less/theme.less') // midiatom style
}
