<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-29 16:03:16
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-12 17:57:08
 * @FilePath: /mediatom-web/src/components/common/StringList/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="string-list-input">
    <div class="head" :class="{'has-list': compValue.length > 0}">
      <a-input class="input" :placeholder="placeholder" v-model.trim="keyword"></a-input>
    </div>
    <div class="btn-box">
      <div class="btn" @click="handleClearAll">清空</div>
      <div class="btn" @click="handleAddString">添加</div>
    </div>
    <div class="main" v-if="compValue.length > 0">
      <div class="search-box">
        <a-input-search class="input" placeholder="请输入搜索关键字" v-model.trim="searchKey"></a-input-search>
      </div>
      <div class="string-item" v-for="(item, index) in compValueSearch" :key="item + index">
        <div class="string">{{ item }}</div>
        <div class="right">
          <CopySvg @click="copyId(item)" class="icon"/>
          <a-icon @click="handleDeleteString(item)" type="close" class="icon"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CopySvg from '@/assets/icons/copy.svg?inline'
import { copy } from '@/utils/string'
export default {
  components: { CopySvg },
  data () {
    return {
      keyword: '',
      searchKey: undefined
    }
  },
  props: {
    placeholder: {
      type: String,
      default: '请输入'
    },
    value: {
      default: () => ([]),
      type: Array
    },
    splitReg: {
      default: () => (/[\r\n,]|[，。]/g),
      type: RegExp
    },
    filter: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    compValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('change', val)
        this.$emit('input', val)
      }
    },
    compValueSearch () {
      if (!this.searchKey) return this.compValue
      return this.compValue.filter((item) => item.includes(this.searchKey))
    }
  },
  watch: {
    value: {
      handler (val) {
        this.keyword = undefined
      },
      immediate: true
    }
  },
  methods: {
    // 添加字段
    handleAddString () {
      if (!this.keyword || !this.keyword.trim()) return
      const list = this.keyword.trim().split(this.splitReg).filter((item) => item).map((item) => item.trim())
      if (this.filter) {
        this.compValue = [...new Set([...this.compValue, ...list])]
      } else {
        this.compValue.push(...list)
      }
      this.keyword = ''
    },
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    // 删除字段
    handleDeleteString (item) {
      // this.compValue = this.compValue.filter((child) => item !== child)
      const index = this.compValue.indexOf(item)
      this.compValue.splice(index, 1)
    },
    // 清除全部
    handleClearAll () {
      this.compValue = []
    }
  }
}
</script>

<style lang="less" scoped>
.string-list-input {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 180px;
  .head{
    border: 1px solid @compBorderColor;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border-radius: 5px;
    &.has-list{
      border-radius: 5px 5px 0 0;
    }
    .input{
      height: 34px!important;
      padding-top: 0;
      padding-bottom: 0;
      border: none!important;
      outline: none!important;
      &:focus{
        border: none!important;
        outline: none!important;
      }
    }
  }
  .btn-box{
    display: flex;
    .btn{
      flex: 1 auto;
      text-align: center;
      height: 36px;
      cursor: pointer;
      color: @assisColor;
      line-height: 36px;
      box-sizing: border-box;
      border-bottom: 1px solid @compBorderColor;
      border-right: 1px solid @compBorderColor;
      &:nth-child(1){
        border-left: 1px solid @compBorderColor;
      }
    }
  }
  .main{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    border: 1px solid @compBorderColor;
    border-top: none;
    border-radius: 0 0 5px 5px;
    max-height: 240px;
    overflow-y: auto;
    .search-box{
      padding: 12px 12px 0 12px;
    }
    .string-item{
      display: flex;
      justify-content: space-between;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      .string{
        width: 80%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .right{
        display: flex;
        justify-content: space-around;
        align-items: center;
        .icon{
          margin: 0 5px;
          width: 12px;
          height: 12px;
          font-size: 12px;
          color: #999;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
