import store from '@/store'
import { getBydomainName } from '@/api/autoweb'
// import {
//   TOGGLE_COLOR
// } from '@/store/mutation-types'
function setHeaderIcon (url) {
  var link = document.createElement('link')
  // link.type = 'image/x-icon';
  link.rel = 'icon'
  link.href = url
  document.getElementsByTagName('head')[0].appendChild(link)
}
const currentOrigin = store.getters.origin
const getOriginConfig = async () => {
  const { data = {} } = await getBydomainName({ domainName: currentOrigin })
  store.dispatch('autoweb/SetWebSetting', data)
  setHeaderIcon(data.headerIcon)
  // store.commit(TOGGLE_COLOR, '#ccc')
}

export { getOriginConfig, setHeaderIcon }
