/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-12 18:33:35
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-20 19:06:15
 * @FilePath: /mediatom-web/src/store/getters.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const getters = {
  isMobile: (state) => state.app.isMobile,
  lang: (state) => state.app.lang,
  theme: (state) => state.app.theme,
  color: (state) => state.app.color,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  nickname: (state) => state.user.name,
  // 角色数组
  roles: (state) => state.user.roles,
  // 用户信息
  userInfo: (state) => state.user.info,
  // 媒体审核权限
  audit: (state) => state.user.audit,
  checkable: (state) => state.user.checkable,
  skip: (state) => state.user.skip,
  addRouters: (state) => state.permission.addRouters,
  allRoutes: (state) => state.permission.allRoutes,
  multiTab: (state) => state.app.multiTab,
  name: (state) => state.user.name,
  allowRegister: (state) => state.autoweb.allowRegister,
  resourceUrl: (state) => state.autoweb.resourceUrl,
  origin: (state) => state.autoweb.origin,
  adplanResourceUrl: (state) => state.autoweb.adplanResourceUrl,
  title: (state) => state.autoweb.title,
  moneyType: (state) => +state.user.cur === 1 ? '$' : '¥',
  isMedPro: (state) => state.autoweb.isMedPro,
  sysUserDashboardSetting: (state) => state.user.sysUserDashboardSetting,
  // 权限
  // 灵敏度
  sensitivity: (state) => state.asyncPermission.sensitivity,
  // 按钮大小
  buttonSize: (state) => state.asyncPermission.buttonSize
}

export default getters
