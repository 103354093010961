<template>
  <div class="cust-select-one-container" ref="custSelect" style="font-size: 14px">
    <a-popover
      ref="popover"
      :overlayStyle="{ width: popoverWidth }"
      placement="bottom"
      overlayClassName="cust-select-wrapper"
      trigger="click"
      arrowPointAtCenter
      @visibleChange="visibleChange"
      destroyTooltipOnHide
    >
      <template #content>
        <div class="wrapper">
          <div class="search">
            <a-input
              ref="searchInput"
              :placeholder="`请输入${label}名称${searchById ? '/ID' : ''}`"
              v-model.trim="searchKey"
              allowClear
            >
              <a-icon slot="suffix" type="search" />
            </a-input>
          </div>
          <div class="show_box" @scroll="handleScroll">
            <a-radio-group :defaultValue="selectData">
              <div
                class="show_item"
                v-for="item in filterList"
                :class="{ active_item: '' + item.id === '' + selectData }"
                :key="item.id"
                :style="{ height: showId ? '48px' : '25px', 'align-items': showId ? 'center' : '' }"
              >
                <a-radio class="radio_item" @click="handleSelect(item.id, item)" :value="item.id">
                  <p class="name" :class="{ active: '' + item.id === '' + selectData }">
                    <IosSvg v-if="item.os === 0 && hasOs" class="icon" /><AndroidSvg
                      v-if="item.os === 1 && hasOs"
                      class="icon"
                    />{{ item.name }}
                  </p>
                  <span v-if="showId" class="id percent-text" :class="{ active: '' + item.id === '' + selectData }">
                    ID:{{ item.id
                    }}<a-tooltip placement="bottom">
                      <template slot="title">
                        <span>复制</span>
                      </template>
                      <!-- <a-icon
                        @click.stop.prevent="copyId(item.id)"
                        style="cursor: pointer; line-height: 14px; margin-left: 5px"
                        type="copy"
                      /> -->
                      <CopySvg @click.stop.prevent="copyId(item.id)" class="percent-text" style="cursor: pointer; margin-left: 5px"/>
                    </a-tooltip>
                  </span>
                </a-radio>
              </div>
              <a-empty
                style="margin-top: 50px"
                :image="simpleImage"
                description="无搜索结果"
                v-if="filterList.length === 0"
              />
            </a-radio-group>
          </div>
        </div>
      </template>
      <div
        v-if="!disabled"
        class="select_wrapper"
        :tabindex="0"
        :class="{ select_box: true, visble: visb }"
        @click="handleClickSelectBox"
      >
        <div class="box_left">
          <span class="left" v-if="hasPosition && positionName">
            <span>{{ positionName }}</span>
          </span>
          <div class="content" :title="dataName">
            <template
              v-if="selectData"
            ><IosSvg v-if="hasOs && currentOs === 0" class="os_icon" /><AndroidSvg
              v-if="currentOs === 1 && hasOs"
              class="os_icon"
            />
              <div class="select-value">{{ dataName }}</div>
            </template
            >
            <span v-if="!selectData" style="color: #ccc">请选择{{ label }}</span>
            <ArrowSvg class="up_icon" :class="{ hasClear: allowClear && selectData }"/>
            <a-icon
              class="close_icon"
              @click.stop="handleClear"
              :class="{ hasClear: allowClear && selectData }"
              type="close"
            />
          </div>
        </div>
      </div>
    </a-popover>
    <div
      v-if="disabled"
      class="select_wrapper"
      :tabindex="0"
      :class="{ select_box: true, visble: visb, disabled: disabled }"
    >
      <div class="box_left">
        <span class="left" v-if="hasPosition && positionName">
          <span>{{ positionName }}</span>
        </span>
        <div class="content" :title="dataName">
          <span
            v-if="selectData"
          ><IosSvg v-if="hasOs && currentOs === 0" class="os_icon" /><AndroidSvg
            v-if="currentOs === 1 && hasOs"
            class="os_icon"
          />{{ dataName }}</span
          >
          <span v-if="!selectData" style="color: #ccc">请选择{{ label }}</span>
          <ArrowSvg class="up_icon" :class="{ hasClear: allowClear && selectData }"/>
          <a-icon
            class="close_icon"
            @click.stop="handleClear"
            :class="{ hasClear: allowClear && selectData }"
            type="close"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import CopySvg from '@/assets/icons/copy.svg?inline'
import { getPositionList } from '@/api/common'
import ArrowSvg from '@/assets/icons/arrow.svg?inline'
import { Empty } from 'ant-design-vue'
import { copy } from '@/utils/string'
export default {
  components: { IosSvg, AndroidSvg, CopySvg, ArrowSvg },
  model: {
    prop: 'selectData',
    event: 'input'
  },
  props: {
    popoverWidth: {
      default: '220px',
      type: String
    },
    // 全部数据
    allData: {
      type: Array,
      default: () => []
    },
    // 已选数据
    selectData: {
      default: '',
      type: [String, Number]
    },
    // 是否显示广告样式
    hasPosition: {
      default: false,
      type: Boolean
    },
    // 是否显示操作系统
    hasOs: {
      default: false,
      type: Boolean
    },
    // 是否可以通过Id搜索
    searchById: {
      default: false,
      type: Boolean
    },
    // 是否允许清除
    allowClear: {
      default: false,
      type: Boolean
    },
    // label
    label: {
      default: '',
      type: String
    },
    // 是否显示ID
    showId: {
      default: true,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      // 保存操作下拉框时box任然有聚焦样式
      visb: false,
      // 广告样式列表
      positionList: [],
      // 搜索字段
      searchKey: '',
      // 长列表优化
      current: 20,
      // 长列表优化loading
      isLoading: false,
      oldVal: undefined
    }
  },
  beforeCreate () {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
  },
  mounted () {
    if (this.hasPosition) {
      this.getPositionList()
    }
  },
  watch: {
    allData: {
      handler (val) {
        if (!val.find((item) => '' + item.id === '' + this.selectData) && this.selectData) {
          this.$emit('input', '')
        }
        this.current = 20
      },
      immediate: true
    }
  },
  computed: {
    // 显示数据，通过searchKey动态产生
    filterList () {
      const filterList = this.searchById
        ? this.allData.filter(
            (item) =>
              item.name?.toLowerCase().includes(this.searchKey.toLowerCase()) ||
              item.id?.toLowerCase().includes(this.searchKey.toLowerCase())
          )
        : this.allData.filter((item) => item.name?.toLowerCase().includes(this.searchKey.toLowerCase()))
      return filterList.length <= this.current ? filterList : filterList.splice(0, this.current)
    },
    // 选择数据的name，用于显示
    dataName () {
      return this.allData.filter((item) => '' + item.id === '' + this.selectData).length > 0
        ? this.allData.filter((item) => '' + item.id === '' + this.selectData)[0].name
        : ''
    },
    currentOs () {
      return this.allData.filter((item) => '' + item.id === '' + this.selectData).length > 0
        ? this.allData.filter((item) => '' + item.id === '' + this.selectData)[0].os
        : undefined
    },
    // 选择数据的广告样式，可选显示
    positionName () {
      if (this.hasPosition) {
        const id =
          this.allData.filter((item) => '' + item.id === '' + this.selectData).length > 0
            ? this.allData.filter((item) => '' + item.id === '' + this.selectData)[0].position
            : ''
        return this.positionList.filter((item) => id === item.id)[0]
          ? this.positionList.filter((item) => id === item.id)[0].name
          : ''
      } else {
        return ''
      }
    }
  },
  methods: {
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    // 保持选择框样式outline
    visibleChange (visb) {
      this.visb = visb
      // 关闭下拉框清空搜索
      !visb || (this.searchKey = '')
      if (!visb) {
        if (this.oldVal !== this.selectData) {
          this.$emit('change', this.selectData)
        }
      } else {
        this.oldVal = this.selectData
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.searchInput.focus()
          }, 0)
        })
      }
    },
    // 挂载到组件容器中
    getPopupContainer () {
      return this.$refs.custSelect
    },
    // 点击选择框重置长列表优化
    handleClickSelectBox () {
      this.current = 20
    },
    // 清空操作
    handleClear () {
      const oldData = this.selectData
      this.$emit('input', '')
      if (oldData) {
          this.$emit('change', '')
      }
    },
    async getPositionList () {
      const resp = await getPositionList()
      this.positionList = resp.data || []
    },
    // 列表项点击事件
    handleSelect (id, item) {
      this.$emit('input', id)
      if (this.oldVal !== id) {
        this.$emit('change', id)
      }
      this.$refs.popover.$children[0].sVisible = false
      this.visb = false
    },
    // 长列表优化
    handleScroll (e) {
      if (this.isLoading) return
      if (e.target.scrollTop + e.target.clientHeight + 10 >= e.target.scrollHeight) {
        this.isLoading = true
        this.current += 10
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
@SearchHeight: 40px;
.cust-select-one-container {
  padding: 0;
  margin: 0;
  position: relative;
  height: 100%;
  width: 100%;
  display: inline-block;
  .select_box {
    cursor: pointer;
    position: relative;
    height: @compDefaultHeight;
    border: 1px solid @compBorderColor;
    width: 100%;
    line-height: @compDefaultHeight;
    padding-left: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #fff;
    transition: all 0.2s;
    &:hover {
      border: 1px solid @primary-color;
    }
    &:focus,
    &.visble {
      border: 1px solid @primary-color;
      outline: none;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .box_left {
      flex: 1, 1;
      width: 100%;
      display: flex;
      padding-right: 25px;
      .left {
        display: inline-block;
        max-width: 800px;
        min-width: 60px;
        text-align: center;
        padding-right: 5px;
        height: 100%;
        border-right: 1px solid @compBorderColor;
        color: @compValueColor;
        font-size: 12px;
      }
      .content {
        width: 0;
        flex: 1 auto;
        white-space: nowrap;
        padding-left: 6px;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.65);
        color: @compValueColor;
        position: relative;
        .select-value{
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .os_icon {
          width: 12px;
          height: 12px;
          margin-right: 3px;
        }
        .up_icon,
        .close_icon {
          position: absolute;
          right: -15px;
          font-size: 12px;
          transition: all 0.2s;
          color: #ccc;
        }
        .close_icon {
          display: none;
          padding: 0 20px;
          cursor: pointer;
        }
      }
    }
    &.visble .content .up_icon {
      transform: rotate(180deg);
      line-height: 30px;
    }
  }
}
.select_wrapper.disabled {
  background-color: @compDisabledBc;
  cursor: not-allowed;
  .box_left .content{
    color: @compDisabledColor!important;
  }
}
.select_wrapper:hover {
  .close_icon.hasClear {
    display: block !important;
    width: 10px;
    height: 40px;
    line-height: 15px;
    text-align: center;
    top: 0px;
    justify-content: center;
    &:hover {
      color: #aaa;
    }
  }
  .up_icon.hasClear {
    display: none;
  }
}
@borderColor: #d9d9d9;
.cust-select-wrapper .wrapper {
  border-radius: 5px;
  height: 280px;
  display: flex;
  flex-direction: column;
  .search {
    margin-top: 3px;
    line-height: @SearchHeight;
    height: @SearchHeight;
    // border-bottom: 1px solid @borderColor;
    padding: 0 5px;
  }
  .show_box {
    flex-grow: 1;
    height: 0;
    border-bottom: 3px solid #fff;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #dbdee5;
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background: #eff0f7;
    }
    .ant-radio-group {
      height: 100%;
      padding: 0 0 4px 0;
      width: 100%;
      display: inline-block;
      box-sizing: border-box;
      position: relative;
      padding: 2px 0 0 4px;
      .show_item {
        position: relative;
        margin: 4px 0;
        display: flex;
        border-radius: 5px;
        background: fade(@primary-color, 1%);
        margin-right: 3px;
        &.active_item {
          background-color: fade(@primary-color, 5%);
        }
        label {
          width: 100%;
        }
        p,
        span {
          padding: 0 0 0 3px;
          margin: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
          &.name {
            height: 25px;
            line-height: 25px;
            font-size: 13px;
            color: @defaultFontColor;
          }
          &.id {
            font-size: 12px;
            line-height: 14px;
            display: flex;
            justify-content: space-between;
          }
          &.active {
            color: @primary-color;
          }
          .icon {
            width: 12px;
            height: 12px;
            font-size: 12px;
            margin-right: 3px;
          }
        }
      }
    }
  }
}
::v-deep.ant-radio-wrapper {
  height: 45px;
  line-height: 1;
  position: relative;
  width: 100%;

  .ant-radio {
    display: none;
  }
  span {
    display: block;
    height: 45px;
    width: 100%;
  }
}
</style>
>
