import request from '@/utils/request'

const api = {
  tradeList: '/app/tradeList',
  businessUserList: 'user/businessUserList',
  advertiserListPage: 'advertiser/advertiserListPage',
  agentList: 'agent/agentList',
  advertiserAdd: 'advertiser/add',
  advertiserUpdate: 'advertiser/update',
  delete: 'advertiser/delete',
  agentListPage: 'agent/agentListPage',
  agentListAdd: 'agent/add',
  agentListUpdate: 'agent/update',
  agentListDelete: 'agent/delete',
  subAccountPager: 'account/userPager', // 新 列表
  // subAccountPager: 'user/subAccountPager', // 旧 人员列表
  // addSubAccount: 'user/addSubAccount', // 旧 新增人员
  addSubAccount: 'account/addUser', // 新 新增人员
  // updateSubAccount: 'user/updateSubAccount', // 旧 编辑人员
  updateSubAccount: 'account/updateUser', // 新 编辑人员
  appList: 'app/list',
  // deleteUser: '/user', // 旧 删除人员
  deleteUser: '/account/deleteUser', // 新 删除人员
  deleteAccount: '/account/deleteAccount',
  accountPager: 'account/accountPager',
  getRoleList: 'role/getRoleList',
  getMenuList: 'role/getMenuList',
  getMenuAndOperateListByRoleId: 'role/getMenuAndOperateListByRoleId',
  addRole: 'role/addRole',
  updateRole: 'role/updateRole',
  getRoleListForRole: 'role/getRoleListForRole',
  getThirdPartyErrorAnalysePage: '/errorAnalyse/getErrorAnalysePage',
  addAccount: 'account/addAccount',
  updateAccount: 'account/updateAccount',
  getAppListByUser: 'account/getAppListByUser',
  getAccountCustomByUserId: 'account/getAccountCustomByUserId',
  accountCustomAdd: 'account/accountCustomAdd',
  accountCustomUpdate: 'account/accountCustomUpdate',
  getAdminList: 'account/getAdminList',
  transferAdmin: 'account/transferAdmin',
  // 通过操作系统查询sdk版本
  getPublishedSdkList: '/accessCenter/getPublishedSdkList',
  // 获取sdk版本报表页
  getSdkPager: '/accessCenter/getSdkPager',
  // 获取广告平台版本报表页
  getSourceSdkPackagePager: '/accessCenter/getSourceSdkPackagePager',
  // 编辑mediatomSDK
  updateSdk: '/accessCenter/updateSdk',
  // 新增mediatomSDK
  addSdk: '/accessCenter/addSdk',
  // 通过id查询sdk绑定的广告源列表
  getSourceSdkById: '/accessCenter/getSourceSdkById',
  // 通过操作系统获取可绑定平台列表
  getSourceSdkList: 'accessCenter/getSourceSdkList',
  // 绑定广告平台
  bindingSource: '/accessCenter/bindingSource',
  addSourceSdkPackage: '/accessCenter/addSourceSdkPackage',
  updateSourceSdkPackage: '/accessCenter/updateSourceSdkPackage',
  // 账户管理 权限绑定
  permissionBindPlat: '/user/bindPlat',
  // 重置key
  resetApiKey: '/user/resetApiKey'
}

export default api
export function deleteUser (data) {
  return request({
    url: api.deleteUser,
    method: 'post',
    data
  })
}
// 账户管理列表删除
export function deleteAccount (data) {
  return request({
    url: api.deleteAccount,
    method: 'post',
    data
  })
}
export function appList () {
  return request({
    url: api.appList,
    method: 'get'
  })
}
export function tradeList () {
  return request({
    url: api.tradeList,
    method: 'get'
  })
}
export function businessUserList () {
  return request({
    url: api.businessUserList,
    method: 'get'
  })
}
export function advertiserListPage (data) {
  return request({
    url: api.advertiserListPage,
    method: 'post',
    data
  })
}
export function agentList (data) {
  return request({
    url: api.agentList,
    method: 'post',
    data
  })
}
export function advertiserAdd (data) {
  return request({
    url: api.advertiserAdd,
    method: 'post',
    data
  })
}
export function advertiserUpdate (data) {
  return request({
    url: api.advertiserUpdate,
    method: 'post',
    data
  })
}
export function deletes (data) {
  return request({
    url: api.delete,
    method: 'post',
    data
  })
}
export function agentListPage (data) {
  return request({
    url: api.agentListPage,
    method: 'post',
    data
  })
}
export function agentListAdd (data) {
  return request({
    url: api.agentListAdd,
    method: 'post',
    data
  })
}
export function agentListUpdate (data) {
  return request({
    url: api.agentListUpdate,
    method: 'post',
    data
  })
}
export function agentListDelete (data) {
  return request({
    url: api.agentListDelete,
    method: 'post',
    data
  })
}
export function subAccountPager (data) {
  return request({
    url: api.subAccountPager,
    method: 'post',
    data
  })
}
export function accountPager (data) {
  return request({
    url: api.accountPager,
    method: 'post',
    data
  })
}
export function addSubAccount (data) {
  return request({
    url: api.addSubAccount,
    method: 'post',
    data
  })
}
export function updateSubAccount (data) {
  return request({
    url: api.updateSubAccount,
    method: 'post',
    data
  })
}
export function getRoleList (data) {
  return request({
    url: api.getRoleList,
    method: 'post',
    data
  })
}
export function getMenuList (data) {
  return request({
    url: api.getMenuList,
    method: 'post',
    data
  })
}
export function getMenuAndOperateListByRoleId (data) {
  return request({
    url: api.getMenuAndOperateListByRoleId,
    method: 'post',
    data
  })
}
export function addRole (data) {
  return request({
    url: api.addRole,
    method: 'post',
    data
  })
}
export function updateRole (data) {
  return request({
    url: api.updateRole,
    method: 'post',
    data
  })
}
// 账户下拉框
export function getAdminList (data) {
  return request({
    url: '/user/adminList',
    method: 'get',
    data
  })
}

export function getLogPage (data) {
  return request({
    url: '/user/getLogPage',
    method: 'post',
    data
  })
}
// 获取基本信息
export function getUserInfo (data) {
  return request({
    url: '/user/info',
    method: 'get',
    data
  })
}
// 修改信息
export function updateAccountInfo (data) {
  return request({
    url: '/user/updateAccountInfo',
    method: 'post',
    data
  })
}
// 获取验证码
export function verifyCode (data) {
  return request({
    url: `/user/verify/${data}`,
    method: 'post'
  })
}
// 修改密码
export function resetPassword (data) {
  return request({
    url: '/user/resetPassword',
    method: 'post',
    data
  })
}
export function getRoleListForRole (data) {
  return request({
    url: api.getRoleListForRole,
    method: 'post',
    data
  })
}

export function getThirdPartyErrorAnalysePage (data) {
  return request({
    url: api.getThirdPartyErrorAnalysePage,
    method: 'post',
    data
  })
}

export function addAccount (data) {
  return request({
    url: api.addAccount,
    method: 'post',
    data
  })
}

export function updateAccount (data) {
  return request({
    url: api.updateAccount,
    method: 'post',
    data
  })
}

export function getAppListByUser (data) {
  return request({
    url: api.getAppListByUser,
    method: 'post',
    data
  })
}

export function getAccountCustomByUserId (data) {
  return request({
    url: api.getAccountCustomByUserId,
    method: 'post',
    data
  })
}

export function accountCustomAdd (data) {
  return request({
    url: api.accountCustomAdd,
    method: 'post',
    data
  })
}

export function accountCustomUpdate (data) {
  return request({
    url: api.accountCustomUpdate,
    method: 'post',
    data
  })
}

export function getAdminLists (data) {
  return request({
    url: api.getAdminList,
    method: 'post',
    data
  })
}

export function transferAdmin (data) {
  return request({
    url: api.transferAdmin,
    method: 'post',
    data
  })
}

export function getPublishedSdkList (data) {
  return request({
    url: api.getPublishedSdkList,
    method: 'post',
    data
  })
}
export function getSdkPager (data) {
  return request({
    url: api.getSdkPager,
    method: 'post',
    data
  })
}
export function getSourceSdkPackagePager (data) {
  return request({
    url: api.getSourceSdkPackagePager,
    method: 'post',
    data
  })
}

export function updateSdk (data) {
  return request({
    url: api.updateSdk,
    method: 'post',
    data
  })
}

export function addSdk (data) {
  return request({
    url: api.addSdk,
    method: 'post',
    data
  })
}

export function getSourceSdkById (data) {
  return request({
    url: api.getSourceSdkById,
    method: 'post',
    data
  })
}

export function getSourceSdkList (os) {
  return request({
    url: api.getSourceSdkList + `?os=${os}`,
    method: 'get'
  })
}

export function bindingSource (data) {
  return request({
    url: api.bindingSource,
    method: 'post',
    data
  })
}

export function addSourceSdkPackage (data) {
  return request({
    url: api.addSourceSdkPackage,
    method: 'post',
    data
  })
}

export function updateSourceSdkPackage (data) {
  return request({
    url: api.updateSourceSdkPackage,
    method: 'post',
    data
  })
}

export function permissionBindPlat (data) {
  return request({
    url: api.permissionBindPlat,
    method: 'post',
    data
  })
}

export function resetApiKey (data) {
  return request({
    url: api.resetApiKey,
    method: 'post',
    data
  })
}
