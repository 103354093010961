<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-02 11:33:55
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-08-01 10:42:19
 * @FilePath: /mediatom-web/src/components/common/MLinkButton/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-button
    v-bind="$attrs"
    v-on="$listeners"
    type="link"
    class="m-link-button"
    :class="{'left-icon': iconPos === 'left', 'right-icon': 'right', 'disabled': $attrs.disabled}">
    <slot class="slot-icon" name="icon" v-if="iconPos === 'left'"></slot>
    <span class="text">
      <slot></slot>
    </span>
    <slot class="slot-icon" name="icon" v-if="iconPos === 'right'"></slot>
  </a-button>
</template>

<script>
export default {
  name: 'MLinkButton',
  props: {
    iconPos: {
      default: 'left',
      type: String
    }
  }
}
</script>

<style lang="less">
 .m-link-button{
  border: none;
  background-color: #00000000;
  word-wrap: normal;
  cursor: pointer;
  font-size: 14px;
  height: @compDefaultHeight;
  padding: 0;
  .text{
    color: #5A607F;
  }
  svg{
    padding: 6px 7px;
    height: 30px;
    width: 30px;
    border: 1px solid #D7DBEC;
    border-radius: 3px;
    vertical-align: middle;
  }
  &.left-icon svg{
    margin-right: 12px;
  }
  &.right-icon svg{
    margin-left: 12px;
  }
  &.disabled svg{
    color: @compDisabledColor!important;
  }
}
</style>
