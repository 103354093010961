import request from '@/utils/request'
const userApi = {
  Login: '/user/login',
  Logout: '/user/logout',
  ForgePassword: '/auth/forge-password',
  twoStepCode: '/auth/2step-code',
  SendSms: '/account/sms',
  SendSmsErr: '/account/sms_err',
  // get my info
  UserInfo: '/user/info',
  // UserMenu: '/role/getMenuList',
  GetCode: '/user/code',
  getMenuListByLoginId: '/user/getMenuListByLoginId'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  const req = request({
    url: userApi.Login,
    method: 'post',
    data: {
      ...parameter
      // ip: window.currentIp
    }
  })
  return req
}

export function getSmsCaptcha (parameter) {
  return request({
    url: userApi.SendSms,
    method: 'post',
    data: parameter
  })
}

export function getInfo () {
  return request({
    url: userApi.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getCurrentUserNav (params) {
  return request({
    url: userApi.getMenuListByLoginId,
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function logout () {
  return request({
    url: userApi.Logout,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: {
      // ip: window.currentIp
    }
  })
}
// 注册
export function register (data) {
  return request({
    url: `/user/register`,
    method: 'post',
    data
  })
}

export function getCodeImg () {
  return request({
    url: userApi.GetCode,
    method: 'get'
  })
}
// 修改素材审核权限
export function updatePermission (data) {
  return request({
    url: `/user/updatePermission`,
    method: 'post',
    data
  })
}
