<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-25 11:47:41
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-09 16:59:32
 * @FilePath: /mediatom-web/src/components/common/MSelectNew/option.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="option-item" @click="handleSelectItem">
    <div class="label">
      <div class="icon" v-if="showOs">
        <IosSvg v-if="item.os === 0" class="icon" />
        <AndroidSvg v-if="item.os === 1" class="icon"/>
      </div>
      <div class="name">{{ item[option.name] }}</div>
    </div>
    <div class="value" :title="item[option.searchId]" v-if="showId || option.searchId !== 'id'">
      <div class="id percent-text">ID:{{ item[option.searchId] }}</div>
      <div class="copy">
        <CopySvg class="copy-icon percent-text" @click.stop="handleCopyId"/>
      </div>
    </div>
  </div>
</template>

<script>
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import { copy } from '@/utils/string'
import CopySvg from '@/assets/icons/copy.svg?inline'
export default {
  name: 'OptionItem',
  components: { IosSvg, AndroidSvg, CopySvg },
  props: {
    item: {
      default: () => ({
        name: undefined,
        id: undefined
      }),
      type: Object,
      required: true
    },
    option: {
      default: () => ({
        name: 'name',
        id: 'id',
        searchId: 'id'
      }),
      type: Object
    },
    // 显示操作系统icon
    showOs: {
      default: false,
      type: Boolean
    },
    // 显示id
    showId: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    handleSelectItem () {
      this.$emit('handleSelectItem', this.item)
    },
    handleCopyId () {
      const result = copy('' + this.item[this.option.id])
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.option-item{
  margin: 3px 5px;
  border-radius: 5px;
  cursor: pointer;
  // background-color: #fafafa;
  padding: 0 6px;
  &:hover{
    color: @primary-color;
    background-color: fade(@primary-color, 5%);
  }
  .label{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
    .icon{
      align-items: center;
      width: 14px;
      height: 14px;
      position: relative;
      margin-right: 5px;
      .icon{
        height: 100%;
        width: 100%;
      }
    }
    .name{
      flex: 1 auto;
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: @defaultFontColor;
    }
  }
  .value{
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 0 5px 0;
    .id{
      width: 80%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .copy{
      width: 20px;
      height: 20px;
      .copy-icon{
        font-size: 14px;
      }
    }
  }
}
</style>
