import request from '@/utils/request'

const dashboardApi = {
  // 首页邴转天数据
  pieChart: '/dashboard/pie-dimension',
  // 首页实时信息
  realTimeData: '/dashboard/realtime_data',
  // 首页汇总信息
  summaryData: '/dashboard/summary_data2',
  // 指标charts
  dashboardChart: '/dashboard/trend',
  // 日志
  operationLog: '/dashboard/personnelDynamic',
  // 应用饼状图
  appAnalyse: '/dashboard/appData',
  // 设置管理员首页模块及累计模式
  dashboardLayoutSetting: '/dashboard/layoutSetting',
  // 首页模板列表
  dashboardTemplateList: '/dashboard/templateList',
  // top公司
  dashboardCompany: '/dashboard/company',
  // 修改是否使用mock状态
  updateMockDataSts: 'user/updateMockDataSts',
  appBrandData: '/dashboard/appBrandData'
}

export function pieChartData (data) {
  return request({
    url: dashboardApi.pieChart,
    method: 'post',
    data: data
  })
}

export function getRealTimeData () {
  return request({
    url: dashboardApi.realTimeData,
    method: 'get'
  })
}
export function getSummaryData (data) {
  return request({
    url: dashboardApi.summaryData,
    method: 'post',
    data
  })
}
export function dashboardChart (data) {
  return request({
    url: dashboardApi.dashboardChart,
    method: 'post',
    data: data
  })
}
export function getOperationLog (data) {
  return request({
    url: dashboardApi.operationLog,
    method: 'post',
    data: data
  })
}

export function barChartDataforApp (data) {
  return request({
    url: dashboardApi.appAnalyse,
    method: 'post',
    data: data
  })
}

export function dashboardLayoutSetting (data) {
  return request({
    url: dashboardApi.dashboardLayoutSetting,
    method: 'post',
    data: data
  })
}

export function dashboardTemplateList (data) {
  return request({
    url: dashboardApi.dashboardTemplateList,
    method: 'post',
    data: data
  })
}

export function dashboardCompany (data) {
  return request({
    url: dashboardApi.dashboardCompany,
    method: 'post',
    data: data
  })
}
export function updateMockDataSts (data) {
  return request({
    url: dashboardApi.updateMockDataSts,
    method: 'post',
    data: data
  })
}

export function getAppBrandData (data) {
  return request({
    url: dashboardApi.appBrandData,
    method: 'post',
    data: data
  })
}
