// eslint-disable-next-line
import * as loginService from '@/api/login'
import store from '@/store'
// 导入icon模块
// import homeSvg from '@/assets/icons/home.svg?inline'
// const iconList = {
//   homeSvg
// }
// eslint-disable-next-line
import { BasicLayout, BlankLayout, PageView, RouteView } from '@/layouts'

// 前端路由表
const constantRouterComponents = {
  // 基础页面 layout 必须引入
  BasicLayout: BasicLayout,
  BlankLayout: BlankLayout,
  RouteView: RouteView,
  PageView: PageView,
  // 你需要动态引入的页面组件
  media: () => import(/* webpackChunkName: "media" */'@/views/media'),
  App: () => import(/* webpackChunkName: "media" */'@/views/media/adMain'),
  // 媒体管理
  Adplace: () => import(/* webpackChunkName: "media" */'@/views/media/Adplace'),
  // 媒体联盟
  MediaAlliance: () => import(/* webpackChunkName: "media" */'@/views/media/MediaAlliance'),
  // 聚合管理
  aggregate: () => import(/* webpackChunkName: "aggregate" */'@/views/aggregate'),
  Config: () => import(/* webpackChunkName: "aggregate" */'@/views/aggregate/Config'),
  // 竞价广告配置
  RtbConfig: () => import(/* webpackChunkName: "aggregate" */'@/views/aggregate/RtbConfig'),
  // ab测试数据
  AbtestData: () => import(/* webpackChunkName: "aggregate" */'@/views/aggregate/AbtestData'),
  // 高级设置
  Advanced: () => import(/* webpackChunkName: "aggregate" */'@/views/aggregate/Advanced'),
  // 批量添加广告源
  batchAddSource: () => import(/* webpackChunkName: "aggregate" */'@/views/aggregate/batchAddsource'),
  // 瀑布流推荐
  recommendTemplate: () => import(/* webpackChunkName: "aggregate" */'@/views/aggregate/recommendTemplate'),
  // 员工管理
  Personnel: () => import(/* webpackChunkName: "system" */'@/views/system/personnel'),
  // SDK版本管理
  SDKManage: () => import(/* webpackChunkName: "system" */'@/views/system/SDKManage'),
  // 角色权限
  Rights: () => import(/* webpackChunkName: "system" */'@/views/system/rights'),
  // 操作日志
  OperationLog: () => import(/* webpackChunkName: "system" */'@/views/system/operationLog'),
  // 角色权限编辑页
  Addingrolerights: () => import(/* webpackChunkName: "system" */'@/views/system/components/addingrolerights'),
  // 异常诊断
  ErrorDiagnose: () => import(/* webpackChunkName: "system" */'@/views/system/ErrorDiagnose'),
  // 资质审核
  Audit: () => import(/* webpackChunkName: "auditManagement" */'@/views/auditManagement/audit'),
  // 审核管理 （创意审核）
  Originality: () => import(/* webpackChunkName: "originality" */'@/views/originality'),
  // 先投后审
  BeforeReview: () => import(/* webpackChunkName: "originality" */'@/views/originality/components/BeforeReview'),
  // 素材快照
  Sckz: () => import(/* webpackChunkName: "originality" */'@/views/originality/sckz'),
  // 财务管理A（合同管理）
  Contract: () => import(/* webpackChunkName: "financialManagementA" */'@/views/financialManagementA/contract'),
  // 收入审核
  IncomeAudit: () => import(/* webpackChunkName: "financialManagementA" */'@/views/financialManagementA/incomeAudit'),
  // 财务管理A（资金管理）
  Money: () => import(/* webpackChunkName: "financialManagementA" */'@/views/financialManagementA/money'),
  // 财务管理A（收入统计）
  Income: () => import(/* webpackChunkName: "financialManagementA" */'@/views/financialManagementA/income'),
  // MergeX结算管理
  ADXSettlementManager: () => import(/* webpackChunkName: "financialManagementA" */'@/views/financialManagementA/ADXSettlementManager'),
  // 支出/结算管理
  PayManager: () => import(/* webpackChunkName: "financialManagementA" */'@/views/financialManagementA/SettlementManager'),
  SettlementManager: () => import(/* webpackChunkName: "financialManagementA" */'@/views/financialManagementA/ApplicationSettle'),
  // 财务管理A（发票管理）
  Invoice: () => import(/* webpackChunkName: "financialManagementA" */'@/views/financialManagementA/invoice'),
  // 财务管理B（合同管理）
  Contractb: () => import(/* webpackChunkName: "financialManagementB" */'@/views/financialManagementB/contractb'),
  // 财务管理B（广告主转账）
  AdvertisingTransfer: () => import(/* webpackChunkName: "financialManagementB" */'@/views/financialManagementB/advertisingTransfer'),
  // 财务管理B（资金管理）
  Moneyb: () => import(/* webpackChunkName: "financialManagementB" */'@/views/financialManagementB/moneyb'),
  // 财务管理B（发票管理）
  Invoiceb: () => import(/* webpackChunkName: "financialManagementB" */'@/views/financialManagementB/invoiceb'),
  // 广告管理
  Advertisement: () => import(/* webpackChunkName: "advertisement" */'@/views/advertisement'),
  // 直客管理
  Sonfig: () => import(/* webpackChunkName: "advertisement" */'@/views/advertisement/sonfig'),
  // 创建编辑直客广告
  AdSteps: () => import(/* webpackChunkName: "advertisement" */'@/views/advertisement/AdSteps'),
  // 广告联盟(直客广告)
  Directpassenger: () => import(/* webpackChunkName: "advertisement" */'@/views/advertisement/Directpassenger'),
  // 报表数据
  ReportDatas: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas'),
  // 综合报表
  SynthReport: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas/SynthReport'),
  // top报表
  // TopReport: () => import('@/views/reportdatas/TopReport'),
  // 小时报表
  HoursReport: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas/HoursReport'),
  // ADX 消耗
  adxcost: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas/adxcost'),
  // 漏斗报表
  FunnelReport: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas/FunnelReport'),
  // 上传第三方数据
  ThirdParty: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas/thirdParty'),
  // 媒体报表
  MediaReport: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas/MediaReport'),
  // 用户行为报表
  UserActionReport: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas/UserActionReport'),
  // 预警
  DataWarning: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas/DataWarning'),
  // 预警报表
  WarningReport: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas/DataWarning/WarningReport'),
  // 预警规则
  WarningRule: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas/DataWarning/WarningRule'),
  // 添加/修改预警规则
  WarningRuleEdit: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas/DataWarning/WarningRuleEdit'),
  // 直投报表
  CustomerReport: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas/CustomerReport'),
  // 媒体结算
  MediaSettle: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas/MediaSettle'),
  // 留存报表
  UsersRetentionReport: () => import(/* webpackChunkName: "reportdatas" */'@/views/reportdatas/UsersRetentionReport'),
  StepsCom: () => import(/* webpackChunkName: "advertisement" */'@/views/advertisement/Directpassenger/components/Steps'),
  // 代理商首页
  AgentDashboard: () => import(/* webpackChunkName: "agentDashboard" */'@/views/agentDashboard'),
  // 个人中心
  AccountInfo: () => import(/* webpackChunkName: "accountInfo" */'@/views/accountInfo'),
  AccountMenu: () => import(/* webpackChunkName: "accountInfo" */'@/views/accountInfo'),
  Account: () => import(/* webpackChunkName: "accountInfo" */'@/views/accountInfo/account'),
  // 消息中心
  Message: () => import(/* webpackChunkName: "accountInfo" */'@/views/accountInfo/Message'),
  // accountManage: () => import(/* webpackChunkName: "accountInfo" */'@/views/accountInfo/accountManage'),
  // 账户管理
  accountManage: () => import(/* webpackChunkName: "system" */'@/views/system/accountManage'),
  // 新增账户
  AddAccountManage: () => import(/* webpackChunkName: "system" */'@/views/system/accountManage/addAccountManage'),
  CreativeMask: () => import(/* webpackChunkName: "system" */'@/views/system/CreativeMask'),
  // 编辑添加屏蔽规则
  EditMaskRule: () => import(/* webpackChunkName: "system" */'@/views/system/EditMaskRule'),
  // 工单系统
  workOrder: () => import(/* webpackChunkName: "workOrder" */'@/views/workOrder'),
  // 新增工单
  addWorkOrder: () => import(/* webpackChunkName: "workOrder" */'@/views/workOrder/addWorkOrder'),
  // 媒体首页
  MediaDashboard: () => import(/* webpackChunkName: "mediadashboard" */'@/views/mediadashboard'),
  // SDK下载
  SDKDownload: () => import(/* webpackChunkName: "SDKDownload" */'@/views/SDKDownload')
}

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  path: '*',
  redirect: '/404',
  hidden: true
}

// 根级菜单
const rootRouter = {
  key: '',
  name: 'index',
  path: '',
  component: 'BasicLayout',
  redirect: '/dashboard',
  meta: {
    title: '首页'
  },
  children: []
}
/**
 * 动态生成菜单
 * @param token
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = (token) => {
  return new Promise((resolve, reject) => {
    loginService
      .getCurrentUserNav()
      .then((res) => {
        if (res.code === 200 && res.data) {
          const tempData = res.data
          const newResult = []
          const auditType = store.state.user.auditType
          const isSuperAdmin = store.state.user.roles && store.state.user.roles[0] === 'SUPER_ADMIN'
          // 路由找到素材审核
          const index = tempData.findIndex((item) => item.name === 'originality')
          if (index !== -1) {
            tempData[index].meta.title = auditType === 0 ? '先投后审' : auditType === 1 ? '先审后投' : ''
            // 超管号同时出现
            isSuperAdmin && (tempData[index].meta.title = '先审后投')
          }
          // 如果是超管账号 添加个子路由先投后审
          if (isSuperAdmin) {
            tempData.push({
              ...tempData[index],
              component: 'BeforeReview',
              name: 'beforeReview',
              meta: {
                ...tempData[index].meta,
                title: '先投后审'
              }
            })
          }
          // 响应数据
          tempData.forEach((titem) => {
            if (titem.name === 'directpassenger') {
              // 如果有创建直客广告的菜单 就把steps 加进去
              newResult.push({
                name: 'steps',
                parentId: titem.parentId,
                id: titem.id,
                meta: {
                  title: '创建直客广告',
                  show: false,
                  order: 1001
                },
                component: 'StepsCom'
              })
              newResult.push({
                component: 'AdSteps',
                meta: {
                  show: false,
                  title: '编辑直客广告',
                  order: 998
                },
                name: 'editadsteps',
                id: 998,
                parentId: 8
              })
              newResult.push({
                component: 'AdSteps',
                meta: {
                  show: false,
                  title: '添加直客广告',
                  order: 999
                },
                name: 'addadsteps',
                id: 999,
                parentId: 8
              })
            } else if (titem.name === 'aggregate') {
              newResult.push(
                ...[
                  {
                    name: 'abtestdata',
                    parentId: titem.id,
                    id: 998,
                    meta: {
                      title: 'A/B测试数据',
                      show: false,
                      order: 1001
                    },
                    component: 'AbtestData'
                  },
                  {
                    name: 'RtbConfig',
                    parentId: titem.id,
                    id: 69,
                    meta: {
                      title: '竞价广告配置',
                      show: false,
                      order: 2
                    },
                    component: 'RtbConfig'
                  },
                  {
                    name: 'advanced',
                    parentId: titem.id,
                    id: 999,
                    meta: {
                      title: '高级设置',
                      show: false,
                      order: 1001
                    },
                    component: 'Advanced'
                  },
                  {
                    name: 'batchAddSource',
                    parentId: titem.id,
                    id: 997,
                    meta: {
                      title: '批量添加广告源',
                      show: false,
                      order: 1001
                    },
                    component: 'batchAddSource'
                  },
                  {
                    name: 'recommendTemplate',
                    parentId: titem.id,
                    id: 996,
                    meta: {
                      title: '添加推荐模版',
                      show: false,
                      order: 1001
                    },
                    component: 'recommendTemplate'
                  }
                ]
              )
            } else if (titem.name === 'rights') {
              // 如果有创建直客广告的菜单 就把steps 加进去
              newResult.push({
                name: 'addingrolerights',
                parentId: titem.parentId,
                id: titem.id,
                meta: {
                  title: '创建角色权限',
                  show: false,
                  order: 1001
                },
                component: 'Addingrolerights'
              })
            } else if (titem.name === 'accountManage') {
              // 如果有账户管理的菜单
              newResult.push({
                name: 'addAccountManage',
                parentId: titem.parentId,
                id: titem.id,
                meta: {
                  title: '创建管理账户',
                  show: false,
                  order: 18
                },
                component: 'AddAccountManage'
              })
            } else if (titem.name === 'workOrder') {
              // 如果有账户管理的菜单
              newResult.push({
                name: 'addWorkOrder',
                parentId: titem.parentId,
                id: titem.id,
                meta: {
                  title: '新建工单',
                  show: false,
                  order: 18
                },
                component: 'addWorkOrder'
              })
            }
          })
          // 一级菜单排序
          const sortRoutes = tempData
            .filter((item) => item.parentId === 0)
            .sort((a, b) => {
              return a.meta.order - b.meta.order
            })
          const childRoutes = tempData.filter((item) => item.parentId !== 0)
          const result = [...sortRoutes, ...childRoutes, ...newResult]
          // 存所有的权限路由 名称
          const localStorageMeuns = result.map((item) => item.name)
          store.commit('SET_ALLROUTERS', localStorageMeuns)
          // icon处理
          // result.forEach(item => {
          //   if (item.parentId === 0) {
          //     item.meta.icon = iconList[item.meta.icon]
          //   }
          // })
          // 当前页总路由数据
          const menuNav = []
          // 总路由中的子路由列表
          const childrenNav = []
          //      后端数据, 根级树数组,  根级 PID
          listToTree(result, childrenNav, 0)
          // 主菜单中的子路由设置
          rootRouter.children = childrenNav
          // 设置主路由信息
          menuNav.push(rootRouter)
          // 通过主路由设置路由列表
          // 树形结构
          const routers = generator(menuNav)
          // 再将404路由加入
          routers.push(notFoundRouter)
          routers.length !== 0 &&
            routers[0].children.forEach((item) => {
              if (item.children) {
                // 二级路由排序，再重定向，防止重定向到不是menu的第一项
                item.children.sort((a, b) => {
                  return a.meta.order - b.meta.order
                })
                // 二级路由动态重定向
                item.redirect =
                  item.children &&
                  item.children.length !== 0 &&
                  `/${item.name}/${item.children && item.children.length !== 0 && item.children[0].name}`
              }
            })
          resolve(routers)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
const generator = (routerMap, parent) => {
  return routerMap.map((item) => {
    const { title, show, hideChildren, hiddenHeaderContent, target, icon, order } = item.meta || {}
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.path || `${(parent && parent.path) || ''}/${item.key}`,
      // 路由名称，建议唯一
      name: item.name || item.key || '',
      // 该路由对应页面的 组件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 该路由对应页面的 组件 :方案2 (动态加载)
      component: constantRouterComponents[item.component || item.key] || (() => import(`@/views/${item.component}`)),

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: title,
        icon: icon || undefined,
        hiddenHeaderContent: hiddenHeaderContent,
        target: target,
        permission: item.name,
        order: order
      }
    }
    // 私有守卫
    if (currentRouter.name === 'abtestdata' || currentRouter.name === 'advanced') {
      currentRouter.beforeEnter = (to, from, next) => {
        if (from.path === '/aggregate/config' || from.path === '/') {
          next()
        } else {
          next(from.path)
        }
      }
    }

    // 是否设置了隐藏菜单
    if (show === false) {
      currentRouter.hidden = true
    }
    // 是否设置了隐藏子菜单
    if (hideChildren) {
      currentRouter.hideChildrenInMenu = true
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
const listToTree = (list, tree, parentId) => {
  list.forEach((item) => {
    // 判断是否为父级菜单
    if (item.parentId === parentId) {
      const child = {
        ...item,
        key: item.key || item.name,
        children: []
      }
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, item.id)
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children
      }
      // 加入到树中
      tree.push(child)
    }
  })
}
