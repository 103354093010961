/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-31 11:39:20
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-07 19:09:11
 * @FilePath: /mediatom-web/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import bootstrap from './core/bootstrap'
// 初始化 babel style
import './init'
// core UI组件按需引入
import '@/core'
// vue filter
import '@/filters' // global filter
// vue plugins
import '@/plugins'
// vue directives
import '@/directives'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex, Logo message
  created: bootstrap,
  render: (h) => h(App)
}).$mount('#app')
