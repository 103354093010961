<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-12 18:33:35
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-23 10:18:55
 * @FilePath: /mediatom-web/src/layouts/TabLayout.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="tab-layout-container fixed-container" v-if="!route.meta.hiddenTab">
      <a-menu v-model="current" mode="horizontal" class="fixed-menu fixed">
        <template v-for="(item, index) in tabList" >
          <a-menu-item :class="{'type-line': showTypeLine(item, index)}" :key="item.path" v-if="!item.hidden">
            <router-link :to="{ name: item.name }" class="link">{{ item.meta.title }}</router-link>
          </a-menu-item>
        </template>
      </a-menu>
      <!-- 垫片 -->
      <a-menu class="fixed-menu gasket">
        <a-menu-item></a-menu-item>
      </a-menu>
      <!-- <transition :name="fadeName"> -->
      <route-view></route-view>
    <!-- </transition> -->
    </div>
    <route-view v-else></route-view>
  </div>
</template>
<script>
import { RouteView } from '@/layouts'
import { mapState } from 'vuex'
export default {
  name: 'TabLayout',
  components: {
    RouteView
  },
  data () {
    return {
      current: [],
      route: undefined
      // fadeName: 'left'
    }
  },
  computed: {
    ...mapState({
      // 权限路由集合
      allRoutes: (state) => state.permission.allRoutes,
      routers: (state) => state.permission.routers
    }),
    tabList () {
      const pName = this.$route.matched.concat().pop().path.split('/')[1]
      if (!pName) {
        return []
      }
      // 默认将异步路由的路由名设为路径
      const baseRoute = this.routers.find((item) => item.name === 'index')
      const parentRoute = baseRoute.children.find((item) => item.name === pName)
      return parentRoute.children
    }
  },
  watch: {
    $route: {
      handler (val, oldVal) {
        this.route = val
        const routes = val.matched.concat()
        this.current = [routes.pop().path]
        // const newOrder = val.meta?.order || 999
        // const oldOrder = oldVal ? oldVal.meta?.order : 999
        // this.fadeName = newOrder > oldOrder ? 'left' : 'right'
      },
      immediate: true
    }
  },
  methods: {
    // 通过前后type判断是否显示路由分类区分线
    showTypeLine (route, index) {
      const currentType = route.meta.type
      if (!currentType) return false
      const nextType = this.tabList[index + 1]?.meta?.type
      // 若下一个路由隐藏，则不显示分界线
      const nextHidden = this.tabList[index + 1]?.hidden
      if (nextType && currentType !== nextType && !nextHidden) return true
      return false
    }
  }
}
</script>
<style lang="less" scoped>
@menuHeight: 60px;
@tabHeight: 48px;
::v-deep.tab-layout-container {
  // min-width: 1280px;
  // user-select: none!important;
  .fixed-menu{
    box-shadow: 0 1px 4px 0 rgba(21, 34, 50, 0.08);
    display: flex;
    align-items: flex-end;
    &.fixed{
      position: fixed;
      width: 100%;
      z-index: 100;
      height: @tabHeight;
      top: @menuHeight;
    }
    &.gasket{
      height: @tabHeight;
      margin-bottom: @mediumMargin;
      opacity: 0;
    }
    .link{
      font-size: 15px;
      font-weight: 400;
      line-height: @tabHeight;
      color: #5a607f;
    }
    .type-line{
      margin-right: 5px;
      &::before{
        content: '';
        width: 2px;
        height: 24px;
        background-color: #15223214;
        position: absolute;
        right: 0;
        top: 12px;
        border-radius: 2px;
      }
    }
  }
      .ant-menu.ant-menu-horizontal {
      border-bottom: none;

      .ant-menu-item {
        border-bottom: none;

        &.ant-menu-item-selected {
          border-color: #fff;
          a{
            font-weight: 500;
            color: @primary-color;
          }

          // &::after {
          //   position: absolute;
          //   content: '';
          //   width: 50%;
          //   height: 3px;
          //   background-color: @primary-color;
          //   left: 25%;
          //   bottom: 0;
          // }
        }

        &:hover {
          border-color: #fff;
        }

        &.ant-menu-item-active {
          border-color: #fff;
        }
      }
    }
}
.left-enter-active,
.left-leave-active,
.right-enter-active,
.right-leave-active {
  opacity: 1;
  transition: all 2s linear;
}
.left-enter {
  // opacity: 0;
  transform: translateX(100%);
}
.left-leave-to {
  // opacity: 0;
  transform: translateX(-100%);
}
.right-enter {
  // opacity: 0;
  transform: translateX(-100%);
}
.right-leave-to {
  // opacity: 1;
  transform: translateX(100%);
}
</style>
