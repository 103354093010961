import request from '@/utils/request'

const api = {
  // 广告样式列表
  positionList: '/common/position_list',
  getAppListForManage: '/app/list',
  getPlaceList: 'place/list',
  // app查广告位列表 1个
  placeListByAppId: '/place/listByAppId',
  // app查广告位列表 多个
  placeListByAppIds: '/place/listByAppIds',
  // 广告平台列表
  getPlatList: '/plat/list',
  // 地区数据
  getAreaList: '/common/area_list',
  // 批量添加广告源页面获取Name
  getNames: '/common/getNames',
  // 设备列表
  getBrandList: '/common/brandList',
  // 广告平台下拉框
  getPlatformList: '/plat/api_platform_list',
  // rtb广告平台
  getRtbPlatformList: 'rtbAd/api_platform_list',
  // rtb竞价广告报表
  getRtbReport: '/rtbAd/report2',
  // rtb删除广告源
  deleteRtbItem: '/rtbAd/delete',
  // rtb账户关联
  getRtbPlatAccounts: 'rtbAd/accountsByPlat',
  // 审核人员的下拉
  getAuditUserList: 'user/audit_user_list',
  // 公司的下拉
  getUserListByCreative: 'user/user_list_by_creative',
  // 获取账号列表
  getAdminList: `/user/adminList`,
  // 通过账户查广告平台
  getPlatListByuser: '/plat/platListByUser',
  // 通过账户查应用列表
  getAppListByAdminId: '/app/getAppListByAdminId',
  // 获取group列表
  getGroupList: '/aggregate/groupList',
  // 通过placeid获取group列表
  groupListByAdplace: '/aggregate/groupListByAdplace',
  // 获取广告源列表
  getSourceList: '/aggregate/sourceList',
  // 获取渠道列表
  getChannelList: '/app/channelList',
  // 广告位查询广告源
  getSourceListByAdplace: '/aggregate/sourceListByAdplace',
  // 获取非直投广告平台的广告平台列表
  platformList: '/plat/platformList',
  // 定向设置获取app list
  getDirectionalSettingAppList: '/adPlan/getDirectionalSettingAppList',
  // 广告源异常诊断
  getSourceError: '/aggregate/source_error',
  // 获取预算广告位
  getDspList: '/rtbAd/list ',
  // 修改rtb状态
  updateSts: '/rtbAd/updateSts',
  // 获取rtb广告平台的广告样式
  platPosition: '/rtbAd/platPosition',
  getRtbDspList: '/rtbAd/dspList',
  getProdAndpackageList: '/common/packageList',
  // 国家列表
  getCountryList: 'common/countryList'
}

export default api

export function getRtbDspList (data) {
  return request({
    url: api.getRtbDspList,
    method: 'post',
    data
  })
}
export function getAppListForManage (data = {
  partnerIdList: [],
  trafficUserIdList: []
}) {
  return request({
    url: api.getAppListForManage,
    method: 'post',
    data
  })
}
export function getPositionList () {
  return request({
    url: api.positionList,
    method: 'get'
  })
}
export function getDspList () {
  return request({
    url: api.getDspList,
    method: 'get'
  })
}
export function getPlaceList () {
  return request({
    url: api.getPlaceList,
    method: 'get'
  })
}
export function getPlaceListByAppId (id) {
  return request({
    url: api.placeListByAppId,
    method: 'post',
    data: id
  })
}

export function getPlaceListByAppIds (id) {
  return request({
    url: api.placeListByAppIds,
    method: 'post',
    data: id
  })
}

export function getPlatList () {
  return request({
    url: api.getPlatList,
    method: 'get'
  })
}
export function getAreaList () {
  return request({
    url: api.getAreaList,
    method: 'get'
  })
}
export function getBrandList () {
  return request({
    url: api.getBrandList,
    method: 'get'
  })
}
// 广告平台下拉框数据请求
export function getPlatformList () {
  return request({
    url: api.getPlatformList,
    method: 'get'
  })
}
// rtb预算平台
export function getRtbPlatformList () {
  return request({
    url: api.getRtbPlatformList,
    method: 'get'
  })
}
// 审核人员的下拉
export function getAuditUserList (params) {
  return request({
    url: api.getAuditUserList,
    method: 'get',
    params
  })
}
// 公司的下拉
export function getUserListByCreative () {
  return request({
    url: api.getUserListByCreative,
    method: 'get'
  })
}

export function getAdminList () {
  return request({
    url: api.getAdminList,
    method: 'get'
  })
}

export function getPlatListByuser (data) {
  return request({
    url: api.getPlatListByuser,
    method: 'post',
    data
  })
}

export function getAppListByAdminId (id) {
  return request({
    url: api.getAppListByAdminId + '/' + id,
    method: 'get'
  })
}

export function getGroupList () {
  return request({
    url: api.getGroupList,
    method: 'get'
  })
}

export function groupListByAdplace (data) {
  return request({
    url: api.groupListByAdplace,
    method: 'post',
    data
  })
}

export function getSourceList () {
  return request({
    url: api.getSourceList,
    method: 'get'
  })
}

export function getChannelList () {
  return request({
    url: api.getChannelList,
    method: 'get'
  })
}

export function getSourceListByAdplace (data) {
  return request({
    url: api.getSourceListByAdplace,
    method: 'post',
    data
  })
}

export function alliancePlatformList () {
  return request({
    url: api.platformList,
    method: 'get'
  })
}

export function getDirectionalSettingAppList () {
  return request({
    url: api.getDirectionalSettingAppList,
    method: 'get'
  })
}

export function getSourceError (data) {
  return request({
    url: api.getSourceError,
    method: 'post',
    data
  })
}
export function getNames (data) {
  return request({
    url: api.getNames,
    method: 'post',
    data
  })
}
export function getRtbReport (data) {
  return request({
    url: api.getRtbReport,
    method: 'post',
    data
  })
}
export function deleteRtbItem (data) {
  return request({
    url: api.deleteRtbItem,
    method: 'post',
    data
  })
}
export function updateSts (data) {
  return request({
    url: api.updateSts,
    method: 'post',
    data
  })
}
export function getRtbPlatAccounts (data) {
  return request({
    url: api.getRtbPlatAccounts,
    method: 'post',
    data
  })
}
export function platPosition (data) {
  return request({
    url: api.platPosition,
    method: 'post',
    data
  })
}
export function getProdAndpackageList () {
  return request({
    url: api.getProdAndpackageList,
    method: 'get'
  })
}
export function getCountryList () {
  return request({
    url: api.getCountryList,
    method: 'post'
  })
}
