<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-12 18:33:35
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-11 15:12:17
 * @FilePath: /mediatom-web/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
      <workModal
        v-if="
          origin === 'https://app.mediatom.cn' &&
            route !== 'workOrder' &&
            route !== 'addWorkOrder' &&
            route !== 'login' &&
            route !== 'register' &&
            !isManage
        "
      ></workModal>
    </div>
  </a-config-provider>
</template>

<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'
import workModal from './views/workOrder/components/workModal'
import { mapState } from 'vuex'
import { defineStyleVar, defaultStyleVar } from './config/settingStyle'
// import { updateTheme } from '@/utils/settingConfig'

export default {
  components: { workModal },
  data () {
    return {
      isManage: process.env.VUE_APP_ISMANAGER === 'true'
    }
  },
  watch: {
    $route: {
      handler (val) {
        this.route = val.name
      },
      immediate: true
    }
  },
  computed: {
    locale () {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && setDocumentTitle(`${i18nRender(title)} - ${domTitle()}`)

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    },
    ...mapState({
      origin: (state) => state.autoweb.origin,
      themeColor: (state) => state.app.color
    })
  },
  mounted () {
    // 注册css变量
    if (process.env.VUE_APP_ISMANAGER === 'true') {
      defineStyleVar(defaultStyleVar)
    }
    // updateTheme(this.themeColor)
  }
}
</script>
<style lang="less" scoped>
// #app {
  // overflow: hidden;
  // width: 100vw;
// }
</style>
