<template>
  <div id="userLayout" :class="['user-layout-wrapper']">
    <div class="container" id="login-layout-container">
      <div class="logo-box" style="" v-if="!isRegister">
        <img :src="loginIcon" class="logo" alt="logo" />
      </div>
      <div class="photo">
        <img :src="loginImage || photo" alt="logo" :draggable="false" />
      </div>
      <div class="user-layout-content">
        <div class="user-layout-header">
          <h2>Hello!</h2>
          <span>欢迎使用{{ title }}平台</span>
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { deviceMixin } from '@/store/device-mixin'
import { mapState } from 'vuex'
export default {
  name: 'UserLayout',
  components: {},
  mixins: [deviceMixin],
  data () {
    return {
      photo: require('@/assets/images/login/user-image.png'),
      maxBackgroundImg: require('@/assets/images/login/user-background.png'),
      minBackgroundImg: require('@/assets/images/login/login-background-phone.jpg'),
      mediaQueryList: null
    }
  },
  computed: {
    isRegister () {
      return this.$route.name === 'register'
    },
    ...mapState({
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters,
      loginIcon: (state) => state.autoweb.loginIcon,
      loginImage: (state) => state.autoweb.loginImage,
      loginBackgroundImage: (state) => state.autoweb.loginBackgroundImage,
      mobileBackgroundImage: (state) => state.autoweb.mobileBackgroundImage,
      title: (state) => state.autoweb.title
      // isMedPro: (state) => state.autoweb.isMedPro
    })
  },
  mounted () {
    document.body.classList.add('userLayout')
    // 媒体查询条件
    const mediaQueryString = '(max-width: 998px)'
    // 媒体查询实例
    this.mediaQueryList = window.matchMedia(mediaQueryString)
    // 添加查询条件变化时的回调函数
    this.mediaQueryList.addListener(this.handleMediaQueryChange)
    // 初始化触发修改背景图
    this.handleChangeBackgroundImg(window.innerWidth < 998)
  },
  beforeDestroy () {
    document.body.classList.remove('userLayout')
    this.mediaQueryList.removeListener(this.handleMediaQueryChange)
  },
  methods: {
    // 媒体查询出触发回调
    handleMediaQueryChange (e) {
      this.handleChangeBackgroundImg(e.matches)
    },
    // 修改背景图
    handleChangeBackgroundImg (isMax) {
      const container = document.getElementById('login-layout-container')
      if (!isMax) {
        container.style.backgroundImage = `url(${this.loginBackgroundImage || this.minBackgroundImg})`
      } else {
        container.style.backgroundImage = `url(${this.mobileBackgroundImage || this.maxBackgroundImg})`
      }
    }
  }
}
</script>

<style lang="less" scoped>
@media (min-width: 998px) {
  #userLayout.user-layout-wrapper {
    .container {
      // background: url(~@/assets/images/login/user-background.png);
      background-size: 100% 100%;
      height: auto;
      width: auto;
      min-height: 100vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      position: relative;
      .logo-box {
        min-width: 220px;
        position: absolute;
        top: 50px;
        left: 50px;
        img {
          width: 12vw;
          min-width: 200px;
        }
      }
      .photo {
        aspect-ratio: 1.5/1;
        width: 45vw;
        min-width: 600px;
        overflow: auto;
        margin-left: 150px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          z-index: 10;
        }
      }
      .user-layout-content {
        background: #fff;
        min-width: 30%;
        border-radius: 12px;
        margin-right: 10%;
        padding: 0 40px;
        .user-layout-header {
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 20px 0 10px 0;
          h2 {
            font-size: 23px;
            line-height: 35px;
            margin: 0;
          }
          font-weight: 500;
        }
      }
    }
  }
}
@media (max-width: 998px) {
  #userLayout.user-layout-wrapper {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    position: relative;
    .container {
      width: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      // background: url(~@/assets/images/login/login-background-phone.jpg);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-clip: content-box;
      .logo-box {
        display: flex;
        justify-content: center;
        img {
          width: 33vw;
        }
      }
      .photo {
        display: none;
      }
      .user-layout-content {
        width: 100%;
        padding: 0 9vw;
        .user-layout-header {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          font-size: 4.8vw;
          padding: 16px 0 20px 0;
          color: #344563;
          h2 {
            display: none;
          }
          font-weight: 500;
        }
      }
    }
  }
}
</style>
