<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-05-10 14:50:14
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-10 14:57:07
 * @FilePath: /mediatom-web/src/components/common/Sts/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="sts-cell" :class="{active: sts}">
    {{ sts ? '开启' : '关闭' }}
  </div>
</template>

<script>
export default {
  name: 'Sts',
  props: {
    sts: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.sts-cell {
  height: 24px;
  line-height: 24px;
  width: 68px;
  border-radius: @smallRadius;
  background-color: #5A607F;
  color: #fff;
  text-align: center;
  font-weight: 500;
  &.active {
    background-color: @assisColor;
  }
}
</style>
