<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-18 16:42:53
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-19 11:32:05
 * @FilePath: /mediatom-web/src/components/common/RatioBox/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="ratio-wrapper">
    <div class="ratio-box" :style="{paddingBottom: `${ratio}%`}">
      <div class="ratio-inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RatioBox',
  props: {
    // 高度与宽度的比值
    ratio: {
      default: 100,
      type: Number
    }
  }
}
</script>

<style lang="less" scoped>
.ratio-wrapper {
  position: relative;
  width: 100%;
  .ratio-box{
    width: 100%;
    position: relative;
    .ratio-inner{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}
</style>
