var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-select-container"},[(_vm.disabled)?_c('div',{staticClass:"m-select",class:{
      'size-medium': _vm.size === 'medium',
      'size-small': _vm.size === 'small',
      'disabled': true
    },attrs:{"tabindex":0}},[(_vm.onlyLabel)?[_c('div',{staticClass:"label"},[_c('span',[_vm._v(_vm._s(_vm.label))]),_c('span',[_vm._v("：")])]),_c('div',{staticClass:"select-value"},[(!_vm.compValue)?_c('div',{staticClass:"empty"},[_vm._v("请选择")]):_vm._e(),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.selectedName || _vm.compValue))])])]:[(!_vm.compValue)?_c('div',{staticClass:"only-placeholder"},[_vm._v("请选择"+_vm._s(_vm.label))]):_c('div',{staticClass:"only-value"},[_vm._v(_vm._s(_vm.selectedName || _vm.compValue))])],_c('div',{staticClass:"arrow-icon",class:{expend: _vm.visible, 'has-value': _vm.compValue}},[_c('ArrowSvg',{staticClass:"svg"})],1)],2):_c('a-popover',{ref:"popover",attrs:{"overlayClassName":"cust-select-wrapper","trigger":"click","placement":"bottom","arrowPointAtCenter":""},on:{"visibleChange":_vm.visibleChange}},[_c('div',{staticClass:"m-select",class:{
        'size-medium': _vm.size === 'medium',
        'size-small': _vm.size === 'small',
        'clearable': _vm.clearable
      },attrs:{"tabindex":0}},[(_vm.onlyLabel)?[_c('div',{staticClass:"label"},[_c('span',[_vm._v(_vm._s(_vm.label))]),_c('span',[_vm._v("：")])]),_c('div',{staticClass:"select-value"},[(_vm.compValue === undefined || _vm.compValue === '')?_c('div',{staticClass:"empty"},[_vm._v("请选择")]):_vm._e(),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.selectedName || _vm.compValue))])])]:[(!_vm.compValue)?_c('div',{staticClass:"only-placeholder"},[_vm._v("请选择"+_vm._s(_vm.label))]):_c('div',{staticClass:"only-value"},[_vm._v(_vm._s(_vm.selectedName || _vm.compValue))])],(_vm.clearable)?_c('div',{staticClass:"close-icon",class:{'has-value': _vm.compValue},on:{"click":function($event){$event.stopPropagation();return _vm.handleClearValue.apply(null, arguments)}}},[_c('a-icon',{attrs:{"type":"close-circle","theme":"filled"}})],1):_vm._e(),_c('div',{staticClass:"arrow-icon",class:{expend: _vm.visible, 'has-value': _vm.compValue}},[_c('ArrowSvg',{staticClass:"svg"})],1)],2),_c('template',{slot:"content"},[_c('div',{staticClass:"dropdown-box"},[(_vm.showSearch)?_c('div',{staticClass:"search-box"},[_c('a-input-search',{ref:"searchInput",attrs:{"placeholder":`请输入${_vm.label}名称${_vm.searchById ? '/ID' : ''}`},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchKey"}})],1):_vm._e(),_c('div',{staticClass:"option-list"},[(_vm.dataListComp.length)?_c('div',{staticClass:"option-list-box",on:{"scroll":_vm.handleScroll}},[_vm._l((_vm.dataListComp),function(item){return [_c('Option',_vm._b({key:item[_vm.option.id],class:{active: '' + item[_vm.option.id] === '' + _vm.compValue},on:{"handleSelectItem":function($event){return _vm.handleSelectItem(item)}}},'Option',{
                  ..._vm.$attrs,
                  option: _vm.option,
                  item
                },false))]})],2):_vm._e(),(!_vm.dataListComp.length)?_c('m-empty',{attrs:{"imgHeight":"100"},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v("暂无数据")]},proxy:true}],null,false,3314914855)}):_vm._e()],1)])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }