<template>
  <div class="time_step_wrapper">
    <div class="time_step_header">
      <div class="time_step_header_items">
        <div class="time_step_header_item">
          <div></div>
          <span>已选</span>
        </div>
        <div class="time_step_header_item">
          <div></div>
          <span>可选</span>
        </div>
      </div>
    </div>
    <div class="time_step_title">
      <div class="time_step_title_left"></div>
      <div class="time_step_title_right">
        <div class="time_step_title_right_top">
          <div class="time_step_title_right_top_item">00:00 - 12:00</div>
          <div class="time_step_title_right_top_item">12:00 - 24:00</div>
        </div>
        <div class="time_step_title_right_bottom">
          <div v-for="item in 24" :key="item">{{ item - 1 }}</div>
        </div>
      </div>
    </div>
    <div class="time_step_content">
      <div class="time_step_content_left">
        <div class="week_day">星期一</div>
        <div class="week_day">星期二</div>
        <div class="week_day">星期三</div>
        <div class="week_day">星期四</div>
        <div class="week_day">星期五</div>
        <div class="week_day">星期六</div>
        <div class="week_day">星期日</div>
      </div>
      <div
        class="time_step_content_right_comp"
        @mousemove="dragMousemove"
        @mouseup="dragMouseUp"
        @mouseleave="dragMouseUp"
      >
        <div class="time_step_content_right_box" :key="stepTimeKey">
          <div class="time_step_day" v-for="(item, index) in compValue" :key="index">
            <div
              class="time_step_hour"
              @mousedown="timeStepMouseDown(index, c, child, $event)"
              @mouseup="timeStepMouseUp(index, c, child)"
              :class="{ active: child === 1 }"
              v-for="(child, c) in item"
              :key="c"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="time_step_footer">
      <span>可拖动鼠标选择时间段</span>
      <span class="clear_step_time" @click="initStepTime">清除</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectWeekTime',
  data () {
    return {
      isMouseDown: false, // 鼠标按下标志
      startInfo: {
        // 鼠标按下起始信息
        day: 0,
        hour: 0,
        active: 0,
        x: undefined,
        y: undefined
      },
      stepTimeKey: 1, // key
      contentDom: null // dom
    }
  },
  computed: {
    compValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  created () {
    this.$set(
      this,
      'compValue',
      this.value || [
        // 周一
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周二
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周三
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周四
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周五
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周六
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周日
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ]
      ]
    )
  },
  mounted () {
    this.contentDom = document.getElementsByClassName('time_step_content_right_comp')[0]
  },
  methods: {
    // 投放时段
    // 投放时段---时间段按下事件
    timeStepMouseDown (day, hour, active, e) {
      this.isMouseDown = true
      this.startInfo = {
        day,
        hour,
        active,
        x: e.clientX,
        y: e.clientY
      }
    },
    // 投放时段---时间段抬起事件
    timeStepMouseUp (day, hour, active) {
      if (!this.isMouseDown) {
        return
      }
      const endInfo = {
        day,
        hour,
        active
      }
      const targetActive = this.startInfo.active === 1 ? 0 : 1
      const startX = this.startInfo.hour > endInfo.hour ? endInfo.hour : this.startInfo.hour
      const startY = this.startInfo.day > endInfo.day ? endInfo.day : this.startInfo.day
      const endX = this.startInfo.hour < endInfo.hour ? endInfo.hour : this.startInfo.hour
      const endY = this.startInfo.day < endInfo.day ? endInfo.day : this.startInfo.day
      for (let hour = startX; hour <= endX; hour++) {
        for (let day = startY; day <= endY; day++) {
          this.compValue[day][hour] = targetActive
        }
      }
      this.stepTimeKey = new Date().getTime()
      this.isMouseDown = false
    },
    // 投放时段---投放时间清空按钮
    initStepTime () {
      this.compValue.forEach((item, i) => {
        item.forEach((child, c) => {
          this.compValue[i][c] = 0
        })
      })
      this.stepTimeKey = new Date().getTime()
    },
    // 投放时段---朦层抬起事件
    dragMouseUp (e) {
      const dragDom = document.getElementsByClassName('drag_dom')[0]
      if (!dragDom) return
      dragDom.style.display = 'none'
      this.isMouseDown = false
    },
    // 投放时段---朦层移动事件
    dragMousemove (e) {
      if (!this.isMouseDown) {
        return
      }
      let dragDom = document.getElementsByClassName('drag_dom')[0]
      if (dragDom) {
        dragDom.style.width = '0px'
        dragDom.style.height = '0px'
      } else {
        dragDom = document.createElement('div')
        dragDom.className = 'drag_dom'
        dragDom.style.background = 'aqua'
        dragDom.style.opacity = '0.3'
        dragDom.style.zIndex = '9999'
        dragDom.style.position = 'absolute'
        dragDom.style.display = 'none'
        this.contentDom.appendChild(dragDom)
      }
      const relativeTop = this.contentDom.getBoundingClientRect().top
      const relativeLeft = this.contentDom.getBoundingClientRect().left
      const width = Math.abs(e.clientX - this.startInfo.x)
      const height = Math.abs(e.clientY - this.startInfo.y)
      dragDom.style.width = width - 4 + 'px'
      dragDom.style.height = height - 4 + 'px'
      const startPlaceX = e.clientX - this.startInfo.x > 0 ? this.startInfo.x - relativeLeft : e.clientX - relativeLeft
      const startPlaceY = e.clientY - this.startInfo.y > 0 ? this.startInfo.y - relativeTop : e.clientY - relativeTop
      dragDom.style.left = startPlaceX + 2 + 'px'
      dragDom.style.top = startPlaceY + 2 + 'px'
      dragDom.style.display = 'inline-block'
    }
  }
}
</script>

<style lang="less" scoped>
// 投放时段
.time_step_wrapper {
  user-select: none;
  border: 1px solid @compBorderColor;
  height: 259px;
  width: 100%;
  max-width: 850px;
  display: flex;
  flex-direction: column;
  color: @defaultFontColor;
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-size: 12px;
  border-radius: 5px;
  .time_step_header {
    height: 25px;
    border-bottom: 1px solid @compBorderColor;
    display: flex;
    justify-content: flex-end;
    padding-right: 6px;
    .time_step_header_items {
      display: flex;
      .time_step_header_item {
        display: flex;
        margin: 0 5px;
        justify-content: center;
        align-items: center;
        div {
          width: 10px;
          margin: 0 5px;
          height: 10px;
          border: 1px solid @compBorderColor;
          border-radius: 10px;
        }
        &:first-child > div {
          background-color: @primary-color;
        }
      }
    }
  }
  .time_step_title {
    height: 50px;
    border-bottom: 1px solid @compBorderColor;
    display: flex;
    .time_step_title_left {
      width: 90px;
      border-right: 1px solid @compBorderColor;
    }
    .time_step_title_right {
      flex-grow: 1;
      .time_step_title_right_top {
        height: 25px;
        border-bottom: 1px solid @compBorderColor;
        display: flex;
        .time_step_title_right_top_item {
          line-height: 25px;
          height: 25px;
          flex: 1;
          text-align: center;
          border-color: #e6e6e6;
          &:first-child {
            border-right: 1px solid @compBorderColor;
          }
        }
      }
      .time_step_title_right_bottom {
        display: flex;
        div {
          flex: 1;
          border-left: 1px solid @compBorderColor;
          line-height: 25px;
          text-align: center;
          border-color: #e6e6e6;
          &:first-child {
            border: none;
          }
        }
      }
    }
  }
  .time_step_content {
    flex-grow: 1;
    display: flex;
    ::after,
    ::before {
      box-sizing: border-box;
    }
    .time_step_content_left {
      width: 90px;
      border-right: 1px solid @compBorderColor;
      display: flex;
      flex-direction: column;
      div.week_day {
        border-top: 1px solid @compBorderColor;
        height: 0;
        line-height: 20px;
        flex: 1 1 auto;
        border-color: #e6e6e6;
        color: @defaultFontColor;
        text-align: center;
        &:first-child {
          border: none;
        }
      }
    }
    .time_step_content_right_comp {
      position: relative;
      flex: 1 1 auto;
      .time_step_content_right_box {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .time_step_day {
          border-top: 1px solid @compBorderColor;
          flex: 1 1 auto;
          box-sizing: border-box;
          display: flex;
          &:first-child {
            border: none;
          }
          .time_step_hour {
            position: relative;
            flex: 1 1 auto;
            resize: none;
            cursor: default !important;
            box-sizing: border-box;
            border-left: 1px solid @compBorderColor;
            &:first-child {
              border: none;
            }
            &:hover {
              background: fade(@primary-color, 10%);
            }
            &.active {
              background: @primary-color;
            }
          }
        }
      }
    }
  }
  .time_step_footer {
    height: 25px;
    border-top: 1px solid @compBorderColor;
    line-height: 25px;
    display: flex;
    position: relative;
    color: @defaultFontColor;
    justify-content: center;
    .clear_step_time {
      position: absolute;
      right: 10px;
      cursor: pointer;
      color: @primary-color;
    }
  }
}
</style>
