var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-config-provider',{attrs:{"locale":_vm.locale}},[_c('div',{attrs:{"id":"app"}},[_c('router-view'),(
        _vm.origin === 'https://app.mediatom.cn' &&
          _vm.route !== 'workOrder' &&
          _vm.route !== 'addWorkOrder' &&
          _vm.route !== 'login' &&
          _vm.route !== 'register' &&
          !_vm.isManage
      )?_c('workModal'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }