import router, { resetRouter } from './router'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import notification from 'ant-design-vue/es/notification'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { getOriginConfig } from '@/utils/autoweb.js'
let hasGetOriginConfig = false
// import { generatorDynamicRouter } from '@/router/generator-routers'
const { generatorDynamicRouter } = require(`@/router/${process.env.VUE_APP_ISMANAGER === 'false' ? 'generator-routers' : 'generator-routers-manage'}`)

NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

const allowList = ['login', 'register', 'registerResult', 'password'] // no redirect allowList
const loginRoutePath = '/user/login'
const defaultRoutePath = '/dashboard'
// 路由守卫
router.beforeEach(async (to, from, next) => {
  // 只获取一次域名配置信息
  if (!hasGetOriginConfig) {
    await getOriginConfig()
    hasGetOriginConfig = true
  }
  NProgress.start() // start progress bar
  to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${domTitle()}`)
  /* has token */
  const token = storage.get(ACCESS_TOKEN)
  if (token) {
    if (to.path === loginRoutePath) {
      // 带参进入
      if (to.query && to.query.to) {
        next({
          name: to.query.to,
          replace: true
        })
        return
      }
      // 不允许登录后跳转至登录页
      next({
        path: defaultRoutePath
      })
      NProgress.done()
    } else {
      // check login user.roles is null
      if (store.getters.roles.length === 0) {
        // request login userInfo
        store
          .dispatch('GetInfo')
          .then((res) => {
            // generate dynamic router
            generatorDynamicRouter(token).then((routers) => {
              store.commit('SET_ROUTERS', routers)
              // 根据roles权限生成可访问的路由表
              // VueRouter@3.5.0+ New API
              resetRouter() // 重置路由 防止退出重新登录或者token过期后页面未刷新，导致的路由重复添加
              store.getters.addRouters.forEach((r) => {
                router.addRoute(r)
              })
              // 带参进入
              if (from.query && from.query.to) {
                next({
                  name: from.query.to,
                  replace: true
                })
                return
              }
              // 非自定义角色 媒体处理
              if (store.getters.roles.length > 0 && store.getters.roles[0] === 'MEDIA' && from.path !== '/') {
                next({
                  path: '/reportdatas/mediareport',
                  replace: true
                })
                return
              }
              // 自定义角色 roles[0]为null， 登录操作非刷新页面
              if (store.getters.roles.length > 0 && !store.getters.roles[0] && from.path !== '/') {
                let custPath = null
                  if (routers[0].children[0].children) {
                  // 无固定页面，跳转至第一个二级路由
                  // 无首页
                  custPath = routers[0].children[0].children[0].path
                  next({
                    path: custPath,
                    replace: true
                  })
                  return
                } else {
                  // 有首页
                  custPath = routers[0].children[0].name
                  next({
                    name: custPath,
                    replace: true
                  })
                  return
                }
              }
              next({
                ...to,
                replace: true
              })
            })
          })
          .catch(() => {
            notification.error({
              message: '错误',
              description: '登录信息失效，请重新登录。'
            })
            // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
            store.dispatch('Logout').then(() => {
              next({
                path: loginRoutePath
              })
            })
          })
      } else {
        next()
      }
    }
  } else {
    // 无token
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({
        path: loginRoutePath
      })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
