/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-12 18:33:35
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-22 11:40:05
 * @FilePath: /mediatom-web/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Router from 'vue-router'

// 将基础路由放到router.js文件下（1）
import { constantRouterMap } from '@/config/router.config'

// hack router push callback
const originalPush = Router.prototype.push

Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(Router)
// (2)
const createRouter = () =>
  new Router({
    mode: 'hash',
    routes: constantRouterMap
  })

const router = createRouter()
// 定义一个resetRouter 方法，在退出登录后或token过期后 需要重新登录时，调用即可
// 在vue-router中，每一个我们定义的路由都会被解析成一个对应的matcher（RouteRecordMatcher类型），路由的增删改查都会依靠matcher来实现。
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}
export default router
