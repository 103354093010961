<template>
  <a-popover class="tips-container" :placement="placement" trigger="hover">
    <template #content>
      <div :style="{ width: width + 'px', color: textColor }" class="manager-text" v-html="content">
      </div>
    </template>
    <div style="cursor: pointer">
      <a-icon v-if="showIcon && iconPosition === 'left'" style="font-size: 12px;color: #858fa1" type="question-circle" />
      {{ title }}
      <a-icon v-if="showIcon && iconPosition === 'right'" style="font-size: 12px;color: #858fa1" type="question-circle" />
    </div>
  </a-popover>
</template>
<script>
export default {
  name: 'HelpHint',
  props: {
    content: { default: '', type: String },
    width: { default: 100, type: Number },
    title: { default: '', type: String },
    showIcon: { default: true, type: Boolean },
    textColor: { default: '', type: String },
    iconPosition: { default: 'right', type: String },
    placement: { default: 'top', type: [String] }
  },

  data () {
    return {}
  }
}
</script>
<style lang="less" scoped>
.tips-container {
  display: inline-block;
  // margin-left: 5px;
}
</style>
