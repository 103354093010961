<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-13 11:22:25
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 11:33:37
 * @FilePath: /mediatom-web/src/components/common/MSelectTree/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <!-- 主体 disabled -->
    <div v-if="disabled" class="m-select" :class="{ disabled: disabled }">
      <div class="select-box" :class="{ isOpen: visible, [size]: size }">
        <div class="label" v-if="label">{{ label }}：</div>
        <div class="placeholder" v-if="!compValue.length">请选择</div>
        <div class="select-num" v-if="compValue.length">已选择{{ compValue.length }}项</div>
        <div class="arrow" :class="{ isOpen: visible, [size]: size }">
          <!-- <a-icon type="down" /> -->
          <ArrowSvg class="svg"/>
        </div>
      </div>
    </div>
    <a-popover
      v-else
      ref="popover"
      placement="bottom"
      overlayClassName="cust-select-wrapper"
      trigger="click"
      arrowPointAtCenter
      @visibleChange="visibleChange"
      destroyTooltipOnHide
    >
      <!-- 主体 -->
      <div class="m-select">
        <div class="select-box" :class="{ isOpen: visible, [size]: size }">
          <div class="label" v-if="label">{{ label }}：</div>
          <div class="placeholder" v-if="!compValue.length">请选择</div>
          <div class="select-num" v-if="compValue.length">已选择{{ compValue.length }}项</div>
          <div class="arrow" :class="{ isOpen: visible, [size]: size }">
            <!-- <a-icon type="down" /> -->
            <ArrowSvg class="svg"/>
          </div>
        </div>
      </div>
      <!-- 下拉显示内容 -->
      <template slot="content">
        <div class="dropdown-box">
          <!-- 左边框 -->
          <div class="select-left">
            <div class="check-all">
              <a-button type="link" class="btn" size="small" @click="handleCheckedAll">全选</a-button>
            </div>
            <div class="search-box">
              <a-input-search
                ref="searchInput"
                :placeholder="`请输入${label}搜索`"
                v-model.trim="searchKey"
              ></a-input-search>
            </div>
            <!-- 树形dom -->
            <div class="tree-list">
              <div class="tree-item-wrapper" v-for="type in treeList" :key="type.id">
                <!-- type -->
                <div class="type-checkbox" @click="handleExpendType(type)">
                  <div class="type-checkbox-left">
                    <a-checkbox
                      @click.stop="handleCheckedType(type)"
                      :checked="type.checkAll"
                      :indeterminate="type.indeterminate"
                    ></a-checkbox>
                    <div class="name">{{ type.name }}</div>
                  </div>
                  <div class="type-checkbox-right" :class="{ 'is-expend': openList.includes(type.id) }">
                    <a-icon type="right" />
                  </div>
                </div>
                <!-- child -->
                <div class="child-list" v-if="openList.includes(type.id)">
                  <div
                    class="child-item"
                    v-for="child in type.childrenList"
                    :key="child.id"
                    @click="handleCheckChildItem(child)"
                  >
                    <a-checkbox :checked="child.checked"></a-checkbox>
                    <div class="name">{{ child.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 有边框 -->
          <div class="select-right">
            <div class="right-head">
              <div class="num">已选择{{ compValue.length }}项&ensp;(共{{ childNum }}项）</div>
              <div class="clear">
                <a-button type="link" class="btn" size="small" @click="compValue.splice(0)">清空</a-button>
              </div>
            </div>
            <!-- 已选dom -->
            <div class="checked-list">
              <div class="checked-item" v-for="item in checkedObjList" :key="item.id">
                <div class="name">{{ item.name }}</div>
                <a-icon type="close" class="icon" @click="handleRemoveItem(item)" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </a-popover>
  </div>
</template>

<script>
import ArrowSvg from '@/assets/icons/arrow.svg?inline'
export default {
  name: 'MSelectTree',
  components: { ArrowSvg },
  data () {
    return {
      // 搜索字段
      searchKey: undefined,
      // 记录下拉框是否显示
      visible: false,
      // 展开type项id数组
      openList: [],
      // 旧数据
      oldCompValue: []
    }
  },
  props: {
    // 绑定值
    value: {
      default: undefined,
      type: Array
    },
    // 选项数组
    options: {
      default: () => [],
      type: Array
    },
    // 大小
    size: {
      default: 'default',
      type: String
    },
    // label
    label: {
      default: undefined,
      type: String
    },
    // 是否禁用
    disabled: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    // 绑定值的计算属性
    compValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    // 选中项的对象数组
    checkedObjList () {
      const options = JSON.parse(JSON.stringify(this.options))
      let list = []
      // 依据compValue的顺序获取数组
      this.compValue.forEach((id) => {
        const childObj = options
          .filter((type) => type.childrenList.some((child) => child.id === id))[0]
          ?.childrenList.filter((child) => child.id === id)
        list = [...list, ...childObj]
      })
      return list
    },
    // 带有选中信息的树形结构
    treeList () {
      const tree = JSON.parse(JSON.stringify(this.options))
      const treeList = []
      tree.forEach((type) => {
        type.childrenList.forEach((item) => {
          // 处理选中状态
          item.checked = this.compValue.includes(item.id)
        })
        // 记录type中选择数
        let checkedNum = type.childrenList.filter((item) => this.compValue.includes(item.id)).length
        // 每个type中的childrenList存放
        let childrenList = type.childrenList.filter((item) => this.searchInclude('' + item.name))
        // 将符合搜索条件的type 和 有符合搜索条件的childrenList 加入到treeList中
        // 是否有搜索值>>>>>搜索值在type名中>>>>>只有部分child被匹配到
        childrenList = !this.searchKey
          ? type.childrenList
          : this.searchInclude('' + type.name)
          ? type.childrenList
          : childrenList.length > 0
          ? childrenList
          : []
        // （有搜索值 且 只有部分child被匹配到） ===> 符合数
        checkedNum =
          this.searchKey && childrenList.length > 0
            ? childrenList.filter((item) => this.compValue.includes(item.id)).length
            : checkedNum
        // type中是否全选
        type.checkAll = checkedNum === childrenList.length
        // type中是否只是部分选中
        type.indeterminate = checkedNum > 0 && checkedNum < childrenList.length
        childrenList.length &&
          treeList.push({
            ...type,
            childrenList
          })
      })
      return treeList
    },
    // 子项总数
    childNum () {
      return this.options.reduce((pre, cur) => pre + cur.childrenList.length, 0)
    }
  },
  methods: {
    // 全选
    handleCheckedAll () {
      this.compValue = this.options.reduce((pre, cur) => pre.concat(cur.childrenList.map((item) => item.id)), [])
    },
    // 移除一项
    handleRemoveItem (item) {
      this.compValue = this.compValue.filter((id) => id !== item.id)
    },
    // 根据所给的参数数组，判断是否符合搜索条件
    searchInclude (...args) {
      if (!this.searchKey) return true
      // 支持模糊搜索
      return args.some((item) => item?.toLowerCase().includes(this.searchKey?.toLowerCase()))
    },
    // type展开
    handleExpendType (type) {
      if (this.openList.includes(type.id)) {
        this.openList = this.openList.filter((id) => id !== type.id)
      } else {
        this.openList.push(type.id)
      }
    },
    // 选择child项
    handleCheckChildItem (child) {
      if (child.checked) {
        this.compValue = this.compValue.filter((id) => id !== child.id)
      } else {
        this.compValue.push(child.id)
      }
    },
    // type的选择框
    handleCheckedType (type) {
      const { childrenList = [], checkAll } = type
      const idList = childrenList.map((item) => item.id)
      if (checkAll) {
        this.compValue = this.compValue.filter((id) => !idList.includes(id))
      } else {
        this.compValue = [...new Set([...this.compValue, ...idList])]
      }
    },
    // 下拉展示变化回调
    visibleChange (e) {
      this.visible = e
      if (e) {
        this.openList = []
        this.searchKey = undefined
        this.$nextTick(() => {
          this.$refs.searchInput && this.$refs.searchInput.focus()
        })
        // 下拉框打开时，保存原选中数组
        this.oldCompValue = [...this.compValue]
      } else {
        // 关闭下拉时，若选中项发生变化，便抛出change事件
        if (
          !(
            this.oldCompValue.every((id) => this.compValue.includes(id)) &&
            this.oldCompValue.length === this.compValue.length
          )
        ) {
          this.$emit('change', this.compValue)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@boxRadius: 5px;
@lightColor: #ccc;
// 主体
.m-select {
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  display: inline-block;
  &.disabled .select-box {
    cursor: not-allowed !important;
    background-color: @compDisabledBc;
    .label{
      color: @compDisabledColor;
    }
    .select-num {
      color: @compValueColor !important;
    }
  }
  .select-box {
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid @compBorderColor;
    border-radius: @boxRadius;
    cursor: pointer;
    position: relative;
    padding: 0 @compPadding;
    display: flex;
    justify-content: flex-start;
    box-shadow: 0 0 0 2px fade(#fff, 0%);
    overflow: hidden;
    // 大小样式
    // 默认
    &.default {
      height: 36px;
      line-height: 36px;
      font-size: 14px;
    }
    // 小
    &.small {
      height: 24px;
      font-size: 12px;
      line-height: 24px;
    }
    // 打开下拉框的主体样式
    &.isOpen {
      border: 1px solid rgba(42, 85, 227, 0.2);
    }
    &:hover{
      border: 1px solid @primary-color;
    }
    .label {
      user-select: none;
      min-width: 30px;
      font-weight: 400;
      color: #5a607f;
    }
    .placeholder {
      user-select: none;
      color: @compDisabledColor;
    }
    .select-num {
      user-select: none;
    }
    .arrow {
      height: 100%;
      position: absolute;
      right: 10px;
      font-size: 12px;
      color: @lightColor;
      display: flex;
      align-items: center;
      .svg{
        transition: all 0.3s;
      }
      &.isOpen .svg {
        transform: rotate(180deg);
      }
    }
  }
}
// 下拉样式
.dropdown-box {
  user-select: none !important;
  width: 450px;
  height: 320px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  // 左边框
  .select-left {
    width: 50%;
    border-right: 1px solid @lightColor;
    display: flex;
    flex-direction: column;
    .search-box,
    .check-all {
      padding: 3px 5px;
    }
    .check-all {
      display: flex;
      padding: 0 5px;
      .btn{
        color: @assisColor;
      }
    }
    .tree-list {
      border-top: 1px solid @lightColor;
      flex: 1;
      height: 0;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #dbdee5;
      }
      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #eff0f7;
      }
      .tree-item-wrapper {
        display: flex;
        cursor: pointer;
        width: 100%;
        position: relative;
        flex-direction: column;
        margin: 3px 0;
        .type-checkbox {
          height: 30px;
          line-height: 20px;
          padding: 3px 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          // background-color: #fafafa;
          .type-checkbox-left {
            display: flex;
            width: 80%;
            .name {
              width: 90%;
              margin-left: 5px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 12px;
            }
          }
          .type-checkbox-right {
            font-size: 12px;
            color: @lightColor;
            transition: all 0.2s;
            &.is-expend {
              transform: rotate(90deg);
            }
          }
          &:hover {
            background-color: #eee;
          }
        }
        .child-list {
          padding: 3px 0;
          .child-item {
            margin: 3px 0;
            // background-color: #fafafa;
            padding: 3px 0 3px 15px;
            display: flex;
            font-size: 12px;
            justify-content: flex-start;
            &:hover {
              background-color: #eee;
            }
            .name {
              width: 100%;
              margin-left: 5px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
  // 右边框
  .select-right {
    width: 0;
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    .right-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
      border-bottom: 1px solid @lightColor;
      width: 100%;
      padding: 0 10px;
      font-size: 12px;
      .btn{
        color: @assisColor;
      }
    }
    .checked-list {
      flex: 1 auto;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #dbdee5;
      }
      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #eff0f7;
      }
      .checked-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        padding: 3px 5px;
        font-size: 12px;
        background-color: #fafafa;
        margin: 3px 0;
        cursor: pointer;
        width: 100%;
        .name {
          width: 90%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
}
</style>
