import request from '@/utils/request'
const api = {
  getBydomainName: '/autoWeb/getBydomainName'
}
export default api
export function getBydomainName (data) {
  return request({
    url: api.getBydomainName,
    method: 'post',
    data
  })
}
