/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-19 14:11:40
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-06 12:06:47
 * @FilePath: /mediatom-web/src/store/modules/fetchpool.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const pool = {
  state: {
    asyncFetchPool: []
    // poolMaxNum: 1
  },
  mutations: {
    SET_POOL: (state, cancelSourceObj) => {
      const { key } = cancelSourceObj
      // 判断池子中是否有该key的请求正在进行
      const index = state.asyncFetchPool.findIndex((item) => item.key === key)
      if (key && index !== -1) {
        // 取消异步线程
        state.asyncFetchPool[index].cancelSource.cancel('请求终止')
        state.asyncFetchPool.splice(index, 1)
        console.warn('请求终止')
      }
      // 将新的异步线程加入数组
      state.asyncFetchPool = [...state.asyncFetchPool, cancelSourceObj]
    },
    REMOVE_POOL: (state, key) => {
      const index = state.asyncFetchPool.findIndex((item) => item.key === key)
      if (key && index !== -1) {
        // 取消异步线程
        state.asyncFetchPool[index].cancelSource.cancel('请求终止')
        state.asyncFetchPool.splice(index, 1)
      }
    }
  },
  actions: {
    setFeatPool ({ commit }, cancelSourceObj) {
      commit('SET_POOL', cancelSourceObj)
    },
    removePool ({ commit }, key) {
      commit('REMOVE_POOL', key)
    }
  }
}

export default pool
