import request from '@/utils/request'

const api = {
  // 通过广告位查流量分组（聚合管理，携带请求占比）
  getGroupIdListByPlaceForAggregate: '/aggregate/group2',
  // 通过广告位查流量分组
  getGroupIdListByPlace: '/aggregate/group',
  getRtaGroupIdListByPlace: '/rtbAd/group',
  // 聚合管理报表
  getAggregateTableData: '/aggregate/source',
  // 修改广告源
  updateSource: '/aggregate/updateSource',
  // 修改优先级
  changePriority: '/aggregate/changePriority',
  // 删除流量分组
  deleteGroupById: '/aggregate/deleteGroupById',
  // 更新流量分组
  updateGroup: '/aggregate/updateGroup',
  // 添加流量分组
  addGroup: '/aggregate/addGroup',
  // 修改默认分组频次
  updateImpress: '/aggregate/updateImpress',
  // 获取流量分组模板列表
  getTemplateList: '/aggregate/templateList',
  // 添加ab测试
  addAbtest: '/abtest/add',
  // 修改ab测试
  updateAbtest: '/abtest/update',
  // 关闭测试分组
  shutdownTest: '/abtest/shutdownTest',
  // 带imgurl的plat列表
  platformList: `/aggregate/platformList/`,
  // 获取账户列表
  getPlatAccounts: '/plat/accounts/',
  // 添加新账户
  addPlatAccount: '/plat/add',
  // 获取素材模板列表
  getCreativeTemplateList: '/creative/template_list',
  // 获取模板类型列表
  gettemplateTypeList: '/aggregate/templateTypeList',
  // 校验添加/编辑广告源
  checkPlatPlaceId: '/aggregate/checkPlatPlaceId',
  // 添加广告源
  addSource: 'aggregate/addSource',
  // 修改mediatom广告源
  updateMediatomAdx: 'aggregate/updateMediatomAdx',
  // 删除广告源
  deleteSource: '/aggregate',
  // 修改代码为频次控制
  adsourceAdvancedSetup: '/aggregate/adsourceAdvancedSetup',
  // 批量添加广告源
  batchAddSource: '/aggregate/batchAddSource',
  // 获取ab测试数据
  getAbTestReport: '/abtest/getAbTestReport',
  getAbTestEchartsReport: '/abtest/getAbTestEchartsReport',
  // 修改瀑布流配置
  updateArgs: '/abtest/update_args',
  // 绑定广告平台
  appBindPlat: '/app/bindPlat',
  autoOperatorBindPlat: '/app/autoOperatorBindPlat',
  // 获取批量复制代码位数据
  getBatchReplicationConfigAdsourceList: '/aggregate/getBatchReplicationConfigAdsourceList',
  // 批量复制广告源
  batchReplicationConfig: '/aggregate/batchReplicationConfig',
  // 批量操作代码位
  batchUpdateSourceSts: '/aggregate/batchUpdateSourceSts',
  // 批量绑定广告平台
  batchBindPlat: 'app/batchBindPlat',
  // 编辑广告源修改应用ID
  updatePlatAppId: '/aggregate/updatePlatAppId',
  autoOperatorUpdatePlatAppId: '/aggregate/autoOperatorUpdatePlatAppId',
  // 批量复制广告源（选择流量分组、测试分组）
  batchCopyAdsource: '/aggregate/batchCopyAdsource',
  // 获取推荐模版
  getRecommendTemplate: '/aggregate/getTemplate',
  // 竞价广告配置
  // 移除配置
  removeConfigured: '/rtbAd/removeConfigured',
  // 树形流量配置
  configured: '/rtbAd/configured',
  // 批量
  configured2: '/rtbAd/configured2',
  // 配置报表
  configuredReport: '/rtbAd/configuredReport',
  // api_platform
  apiPlatform: '/rtbAd/api_platform_list',
  // 频次控制
  frequencyControl: '/rtbAd/frequencyControl',
  // 列表
  report: '/rtbAd/report',
  // 新增
  addRtb: '/rtbAd/add',
  // 编辑配置
  updateConfigured: 'rtbAd/updateConfigured',
  // 批量编辑
  updateConfigured2: 'rtbAd/updateConfigured2',
  // 编辑
  updateRtb: '/rtbAd/update',
  // 广告主样式
  platPosition: '/rtbAd/platPosition',
  // 编辑应用ID
  updateRtbPlatAppId: '/rtbAd/updatePlatAppId',
  // 广告平台
  platFormList: '/plat/platFormList',
  // 获取批量流量配置的数据
  getRtbTreeData: '/rtbAd/configuredReport2',
  getDspById: 'rtbAd/getDspById',
  // 竞价广告源状态开关
  updateConfiguredSts: '/rtbAd/updateConfiguredSts',
  // 获取预算广告位子表
  reportDetails: '/rtbAd/reportDetails',
  // 修改dsp广告位状态
  updateDspSts: '/rtbAd/updateSts',
  // 批量修改dsp广告位状态
  batchChangePlaceSts: '/rtbAd/updateConfiguredSts',
  // 获取账户历史替换包名等信息
  replacerInfo: '/rtbAd/replacerInfo',
  // 修改adx同步状态
  updateAdxSts: '/rtbAd/updateAdxSts',
  // 修改adx同步信息
  updateSettleStrategy: '/rtbAd/updateSettleStrategy',
  // 修改广告位样式配置
  updatePlaceStyle: '/aggregate/updatePlaceStyle'
}

export default api

export function getGroupIdListByPlaceForAggregate (data) {
  return request({
    url: api.getGroupIdListByPlaceForAggregate,
    method: 'post',
    data
  })
}
export function getGroupIdListByPlace (data) {
  return request({
    url: api.getGroupIdListByPlace,
    method: 'post',
    data
  })
}
export function getRtaGroupIdListByPlace (data) {
  return request({
    url: api.getRtaGroupIdListByPlace,
    method: 'post',
    data
  })
}
export function getAggregateTableData (data) {
  return request({
    url: api.getAggregateTableData,
    method: 'post',
    data
  })
}
export function updateSource (data) {
  return request({
    url: api.updateSource,
    method: 'post',
    data
  })
}

export function changePriority (data) {
  return request({
    url: api.changePriority,
    method: 'post',
    data
  })
}
// 删除流量分组
// 地址拼接中?开头第一个参数
export function deleteGroupById (data) {
  return request({
    url: api.deleteGroupById + `?groupId=${data.groupId}`,
    method: 'get'
  })
}

export function updateGroup (data) {
  return request({
    url: api.updateGroup,
    method: 'post',
    data
  })
}
export function addGroup (data) {
  return request({
    url: api.addGroup,
    method: 'post',
    data
  })
}

export function updateImpress (data) {
  return request({
    url: api.updateImpress,
    method: 'post',
    data
  })
}

export function getTemplateList (data) {
  return request({
    url: api.getTemplateList,
    method: 'post',
    data
  })
}
export function addAbtest (data) {
  return request({
    url: api.addAbtest,
    method: 'post',
    data
  })
}
export function updateAbtest (data) {
  return request({
    url: api.updateAbtest,
    method: 'post',
    data
  })
}

export function shutdownTest (data) {
  return request({
    url: api.shutdownTest,
    method: 'post',
    data
  })
}

export function platformLinkList (appId) {
  return request({
    url: api.platformList + appId,
    method: 'post'
  })
}

export function getPlatAccounts (data) {
  return request({
    url: api.getPlatAccounts,
    method: 'post',
    data
  })
}

export function addPlatAccount (data) {
  return request({
    url: api.addPlatAccount,
    method: 'post',
    data
  })
}

export function getCreativeTemplateList () {
  return request({
    url: api.getCreativeTemplateList,
    method: 'get'
  })
}

export function gettemplateTypeList () {
  return request({
    url: api.gettemplateTypeList,
    method: 'get'
  })
}

export function checkPlatPlaceId (data) {
  return request({
    url: api.checkPlatPlaceId,
    method: 'post',
    data
  })
}
export function addSource (data) {
  return request({
    url: api.addSource,
    method: 'post',
    data
  })
}
export function updateMediatomAdx (data) {
  return request({
    url: api.updateMediatomAdx,
    method: 'post',
    data
  })
}
export function deleteSource (data) {
  return request({
    url: api.deleteSource,
    method: 'post',
    data
  })
}

export function adsourceAdvancedSetup (data) {
  return request({
    url: api.adsourceAdvancedSetup,
    method: 'post',
    data
  })
}
export function batchAddSource (data) {
  return request({
    url: api.batchAddSource,
    method: 'post',
    data
  })
}

export function getAbTestReport (data) {
  return request({
    url: api.getAbTestReport,
    method: 'post',
    data
  })
}

export function getAbTestEchartsReport (data) {
  return request({
    url: api.getAbTestEchartsReport,
    method: 'post',
    data
  })
}

export function updateArgs (data) {
  return request({
    url: api.updateArgs,
    method: 'post',
    data
  })
}
export function appBindPlat (data) {
  return request({
    url: api.appBindPlat,
    method: 'post',
    data
  })
}

export function appBindPlatForAutoOperator (data) {
  return request({
    url: api.autoOperatorBindPlat,
    method: 'post',
    data
  })
}

export function getBatchReplicationConfigAdsourceList (data) {
  return request({
    url: api.getBatchReplicationConfigAdsourceList,
    method: 'post',
    data
  })
}

export function batchReplicationConfig (data) {
  return request({
    url: api.batchReplicationConfig,
    method: 'post',
    data
  })
}

export function batchUpdateSourceSts (data) {
  return request({
    url: api.batchUpdateSourceSts,
    method: 'post',
    data
  })
}

export function batchBindPlat (data) {
  return request({
    url: api.batchBindPlat,
    method: 'post',
    data
  })
}

export function updatePlatAppId (data) {
  return request({
    url: api.updatePlatAppId,
    method: 'post',
    data
  })
}

export function updatePlatAppIdForAutoOperator (data) {
  return request({
    url: api.autoOperatorUpdatePlatAppId,
    method: 'post',
    data
  })
}

export function batchCopyAdsource (data) {
  return request({
    url: api.batchCopyAdsource,
    method: 'post',
    data
  })
}

export function getRecommendTemplate (data) {
  return request({
    url: api.getRecommendTemplate,
    method: 'post',
    data
  })
}

// 移除配置
export function removeConfigured (data) {
  return request({
    url: api.removeConfigured,
    method: 'post',
    data
  })
}

// 配置or批量配置
export function configured (data) {
  return request({
    url: api.configured,
    method: 'post',
    data
  })
}
export function configured2 (data) {
  return request({
    url: api.configured2,
    method: 'post',
    data
  })
}

// 配置列表
export function configuredReport (data) {
  return request({
    url: api.configuredReport,
    method: 'post',
    data
  })
}

// 频次控制
export function frequencyControl (data) {
  return request({
    url: api.frequencyControl,
    method: 'post',
    data
  })
}

// 列表
export function dspSourceReport (data) {
  return request({
    url: api.report,
    method: 'post',
    data
  })
}

// 广告主样式
export function getPlatPosition (data) {
  return request({
    url: api.platPosition,
    method: 'post',
    data
  })
}

// 新增广告源
export function addRtb (data) {
  return request({
    url: api.addRtb,
    method: 'post',
    data
  })
}
export function updateConfigured (data) {
  return request({
    url: api.updateConfigured,
    method: 'post',
    data
  })
}

export function updateConfigured2 (data) {
  return request({
    url: api.updateConfigured2,
    method: 'post',
    data
  })
}

// 新增广告源
export function updateRtb (data) {
  return request({
    url: api.updateRtb,
    method: 'post',
    data
  })
}

// 编辑应用ID
export function updateRtbPlatAppId (data) {
  return request({
    url: api.updateRtbPlatAppId,
    method: 'post',
    data })
  }

export function getPlatFormList (data) {
  return request({
    url: api.platFormList,
    method: 'post',
    data
  })
}

// 预算平台下拉框数据请求
export function getApiPlatformList () {
  return request({
    url: api.apiPlatform,
    method: 'get'
  })
}

export function getRtbTreeData (data) {
  return request({
    url: api.getRtbTreeData,
    method: 'post',
    data
  })
}

export function getDspById (data) {
  return request({
    url: api.getDspById,
    method: 'post',
    data
  })
}
export function updateConfiguredSts (data) {
  return request({
    url: api.updateConfiguredSts,
    method: 'post',
    data
  })
}

export function reportDetails (data) {
  return request({
    url: api.reportDetails,
    method: 'post',
    data
  })
}

export function updateDspSts (data) {
  return request({
    url: api.updateDspSts,
    method: 'post',
    data
  })
}

export function batchChangePlaceSts (data) {
  return request({
    url: api.batchChangePlaceSts,
    method: 'post',
    data
  })
}

export function replacerInfo (data) {
  return request({
    url: api.replacerInfo,
    method: 'post',
    data
  })
}

export function updateAdxSts (data) {
  return request({
    url: api.updateAdxSts,
    method: 'post',
    data
  })
}

export function updateSettleStrategy (data) {
  return request({
    url: api.updateSettleStrategy,
    method: 'post',
    data
  })
}

export function updatePlaceStyle (data) {
  return request({
    url: api.updatePlaceStyle,
    method: 'post',
    data
  })
}
