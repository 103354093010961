import axios from 'axios'
import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import modal from 'ant-design-vue/es/modal'
import message from 'ant-design-vue/es/message'
import { resetRouter } from '@/router'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
let flag = false
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // timeout: 30000, // 请求超时时间
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=utf-8'
  }
})

// 异常拦截处理器
const errorHandler = (error) => {
  // console.log(error, 'error')
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use((config) => {
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Authorization'] = token
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  const resp = response.data
  const headers = response.headers
  if (resp.code !== 200) {
    if (resp.code === 10002) {
      message.error(resp.msg)
      return resp
    } else if (resp.code === 50008 || resp.code === 50014) {
      store.commit('SET_TOKEN', '')
      store.commit('SET_ROLES', [])
      storage.set(ACCESS_TOKEN, '')
      store.commit('SET_ROUTERS', [])
      resetRouter()
      !flag && modal.info({
        title: '登录失效',
        okText: '重新登录',
        content: resp.msg,
        centered: true,
        onOk: () => {
          store.dispatch('resetToken').then(() => {
            location.reload()
            flag = false
        })
        }
      })
      flag = true
      return resp
    } else if (resp.code === 10001 || resp.code === 10002 || resp.code === 10003) {
      // 10002 验证码错误  验证码过期
      // 10001 验证广告源已存在
      // 10003 密码错误
      return resp
    } else {
      // !200
      if ('code' in resp) {
        message.error(resp.msg)
      }
      return resp
    }
  }
  // 无痛刷token
  if (headers && (headers['authorization'] || headers['Authorization']) && store.getters.token) {
    const token = headers['authorization'] ? headers['authorization'] : headers['Authorization']
    store.commit('SET_TOKEN', token)
    // console.log(token)
    storage.set(ACCESS_TOKEN, token, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
  }
  return resp
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}
export default request
export { installer as VueAxios, request as axios }
