<template>
  <div @click.stop>
    <div class="workBox">
      <div class="workModal" v-show="Visible">
        <template>
          <img class="workBg" :src="workBg" alt="" />
          <span class="help">请问有什么可以帮您？</span>
        </template>
        <div class="questionBox">
          <p>常见问题</p>
          <div class="question">
            <div class="questionOne">
              <a-button class="a-button" target="_blank" href="https://app.mediatom.cn/docs/#/?blogid=120">mediatom平台变现流程</a-button>
              <a-button class="a-button" target="_blank" href="https://app.mediatom.cn/docs/#/?blogid=120"><a-icon type="right"></a-icon></a-button>
            </div>
            <div class="questionOne">
              <a-button class="a-button" target="_blank" href="https://app.mediatom.cn/docs/#/?blogid=212">如何接入Android SDK</a-button>
              <a-button class="a-button" target="_blank" href="https://app.mediatom.cn/docs/#/?blogid=212"><a-icon type="right"></a-icon></a-button>
            </div>
            <div class="questionOne">
              <a-button class="a-button" target="_blank" href="https://app.mediatom.cn/docs/#/?blogid=221">如何接入IOS SDK</a-button>
              <a-button class="a-button" target="_blank" href="https://app.mediatom.cn/docs/#/?blogid=221"><a-icon type="right"></a-icon></a-button>
            </div>
            <div class="questionOne">
              <a-button class="a-button" target="_blank" href="https://app.mediatom.cn/docs/#/?blogid=228">如何创建应用和广告位</a-button>
              <a-button class="a-button" target="_blank" href="https://app.mediatom.cn/docs/#/?blogid=228"><a-icon type="right"></a-icon></a-button>
            </div>
            <div class="questionOne">
              <a-button class="a-button" target="_blank" href="https://app.mediatom.cn/docs/#/?blogid=129">广告位配置需要注意哪些</a-button>
              <a-button class="a-button" target="_blank" href="https://app.mediatom.cn/docs/#/?blogid=129"><a-icon type="right"></a-icon></a-button>
            </div>
            <div class="questionOne">
              <a-button class="a-button" target="_blank" href="https://app.mediatom.cn/docs/#/?blogid=197">了解更多，前往帮助中心</a-button>
              <a-button class="a-button" target="_blank" href="https://app.mediatom.cn/docs/#/?blogid=197"><a-icon type="right"></a-icon></a-button>
            </div>
          </div>
          <div class="btnBox">
            <a-button
              class="document"
              target="_blank"
              href="https://app.mediatom.cn/docs/">帮助文档
            </a-button>
            <div @click="goWork" class="work">工单系统</div>
          </div>
        </div>
      </div>
    </div>
    <div @click="clickBtn" class="button">
      <img :src="workIcon" alt="" />
      <div class="dot" v-if="workOrderReadSts"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixGetList from '@/mixins/getList'
const workIcon = require('@/assets/images/workIcon.png')
const workBg = require('@/assets/images/workBg.png')
export default {
  name: 'WorkModal',
  mixins: [mixGetList],
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      Visible: false,
      workIcon,
      workBg
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    personInfo: {
      handler (val) {},
      deep: true,
      immediate: true
    }
  },
  computed: {
    personVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    ...mapState({
      // 工单状态
      workOrderReadSts: (state) => state.user.workOrderReadSts
    })
  },
  mounted () {
    document.addEventListener('click', this.bodyCloseMenus)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.bodyCloseMenus)
  },
  methods: {
    bodyCloseMenus (e) {
      const self = this
      if (self.Visible === true) {
        self.Visible = false
      }
    },
    // 点击工单系统按钮
    goWork () {
      this.$router.push({
        path: `/accountInfo/workOrder`
      })
      this.Visible = false
    },
    // 点击icon，展示弹窗
    clickBtn () {
      this.Visible = !this.Visible
    },
    // 选择应用
    changApp (role, option) {
      this.$set(this.personInfo, 'appList', role)
    }
  }
}
</script>

<style lang="less" scoped>
.workBox {
  position: fixed;
  right: 75px;
  bottom: 10%;
  border-radius: 15px;
  display: flex;
  z-index: 101;
  align-items: flex-end;
  .workModal {
    min-height: 300px;
    cursor: pointer;
    width: 340px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(214, 214, 214, 0.5);
    border-radius: 8px;
    border: 0px solid #979797;
    .workBg {
      width: 100%;
      height: 80px;
      position: relative;
    }
    .help {
      position: absolute;
      left: 20px;
      top: 33px;
      font-weight: 600;
      font-size: 18px;
      color: #fff;
    }
    .questionBox {
      padding: 10px 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      p {
        font-weight: 600;
        color: #42433e;
        font-size: 16px;
      }
      .question {
        background: #f5f7f9;
        padding: 20px;
        border-radius: 8px;
        .questionOne {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 5px 0;
          .a-button {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            border: none;
            box-shadow: none;
            color: #4b4c46;
            padding: 0;
          }
          .a-button:hover {
            background: none;
          }
        }
      }
      .btnBox {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        margin-top: 15px;
        .document {
          width: 46%;
          height: 40px;
          line-height: 40px;
          background: #fafafa;
          border-radius: 25px;
          border: 1px solid #e6e6e6;
          text-align: center;
        }
        .work {
          width: 46%;
          height: 40px;
          line-height: 40px;
          background: @primary-color;
          border-radius: 25px;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }
}
.button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  right: 15px;
  bottom: 10%;
  img {
    width: 100%;
    height: 100%;
  }
  .dot{
    width: 5px;
    height: 5px;
    box-shadow: 0 0 3px 0 red;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
.button:hover {
  img {
    content: url(../../../assets/images/workIconHover.png);
  }
}
::v-deep {
  .ant-btn::before {
    background: none;
  }
  .ant-btn {
    background: #f5f7f9;
  }
}
</style>
