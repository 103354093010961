/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-16 15:20:57
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-19 14:21:15
 * @FilePath: /mediatom-web/src/store/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import autoweb from './modules/autoweb'
import dashboardAutoFontSize from './modules/dashboardAutoFontSize'
import fetchPool from './modules/fetchpool'

// default router permission control
import asyncPermission from './modules/permission'
// dynamic router permission control (Experimental)
import permission from './modules/async-router'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    permission,
    autoweb,
    asyncPermission,
    dashboardAutoFontSize,
    fetchPool
  },
  state: {},
  mutations: {},
  actions: {},
  getters
})
