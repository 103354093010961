/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-18 16:47:16
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-08-06 16:19:38
 * @FilePath: /mediatom-web/src/core/custom.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
// common 共用组件全局挂载
import Tips from '@/components/common/tips'
import CustSelectOne from '@/components/common/CustSelectOne'
import CustSelectMultiple from '@/components/common/CustSelectMultiple'
import MSelectTree from '@/components/common/MSelectTree'
import MSelect from '@/components/common/MSelect'
import mEmpty from '@/components/common/empty'
import vuedraggable from 'vuedraggable'
import RatioBox from '@/components/common/RatioBox'
import MLinkButton from '@/components/common/MLinkButton'
import '@/assets/less/custSelectCommon.less'
import DeleteModal from '@/components/DeleteModal'
import StringListInput from '@/components/common/StringList'
import IconTips from '@/components/common/IconTips'
import Sts from '@/components/common/Sts'
import SelectWeekTime from '@/components/common/SelectWeekTime'
// 提示tip
Vue.component('m-tips', Tips)
// 单选
Vue.component('m-select-one', CustSelectOne)
// 多选
Vue.component('m-select-more', CustSelectMultiple)
// 空内容
Vue.component('m-empty', mEmpty)
// 拖拽
Vue.component('m-vuedraggable', vuedraggable)
// 单选 构建中
Vue.component('m-select', MSelect)
// 树形多选
Vue.component('m-select-tree', MSelectTree)
// 比例盒子
Vue.component('m-ratio-box', RatioBox)
// icon链接按钮
Vue.component('m-link-button', MLinkButton)
// 删除弹窗
Vue.component('m-delete-modal', DeleteModal)
// 添加字符串数组
Vue.component('m-string-list', StringListInput)
// icon提示tip
Vue.component('m-icon-tips', IconTips)
// 状态回显
Vue.component('m-sts', Sts)
// 时间段选择
Vue.component('m-week-times', SelectWeekTime)
