/**
 * 向后端请求用户的菜单，动态生成路由
 */
import { constantRouterMap } from '@/config/router.config'

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
    allRoutes: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    },
    SET_ALLROUTERS: (state, allRoutes) => {
      state.allRoutes = allRoutes
    },
    CHANGE_ORIGIN: (state, type) => {
      const index = state.routers.find((item) => item.name === 'index')
      const origin = index.children.find((item) => item.name === 'accountInfo').children.find((item) => item.name === 'originality')
      origin.meta.title = type === 1 ? '先审后投' : '先投后审'
    }
  },
  actions: {
    setOriginTitile ({ commit }, type) {
      commit('CHANGE_ORIGIN', type)
    }
  }
}

export default permission
