// 首页fontsize控制
const state = {
  fontSize: 14
}
const mutations = {
  SET_FONTSIZE: (state, fontSize) => {
    state.fontSize = fontSize || 14
  }
}

const actions = {
  SetAutoFontSize ({ commit }, data = {}) {
    commit('SET_FONTSIZE', data)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
