/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-12 18:33:35
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-22 15:28:41
 * @FilePath: /mediatom-web/src/mixins/autoFontSize.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import store from '@/store'
import { mapState } from 'vuex'
import { defineStyleVar } from '@/config/settingStyle'
export default {
  computed: {
    ...mapState({
      fontSize: (state) => state.dashboardAutoFontSize.fontSize
    })
  },
  methods: {
    handleResize () {
      const innerWidth = window.innerWidth
      if (innerWidth < 1440) {
        store.dispatch('dashboardAutoFontSize/SetAutoFontSize', 14)
        defineStyleVar({
          '--fontSize': 14
        })
        return
      }
      store.dispatch('dashboardAutoFontSize/SetAutoFontSize', innerWidth / 100)
      defineStyleVar({
        '--fontSize': innerWidth / 100
      })
    }
  }
}
