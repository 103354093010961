<template>
  <a-modal
    v-model="delVisibles"
    :title="isDelete ? '删除' + (title) : title"
    :closable="false"
    centered
    wrapClassName="deleteModal"
    :style="{'font-size': fontSize + 'px'}"
  >
    <span class="title" v-if="isDelete">
      确定删除该{{ title }}吗？
    </span>
    <span class="title">
      {{ desc }}
      <slot/>
    </span>
    <template slot="footer">
      <a-button @click="cancle" v-if="hasCancle">取消</a-button>
      <a-button type="primary" @click="submit">确认</a-button>
    </template>
  </a-modal>
</template>

<script>
import autoFontSize from '@/mixins/autoFontSize'
export default {
  name: 'DeleteModal',
  mixins: [autoFontSize],

  data () {
    return {
    }
  },
  props: {
    hasCancle: {
      default: true,
      type: Boolean
    },
    delVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    isDelete: {
      type: Boolean,
      default: true
    },
    desc: {
      type: String,
      default: ''
    }
  },
  watch: {},
  computed: {
    delVisibles: {
      get () {
        return this.delVisible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  created () {},
  methods: {
    cancle () {
      this.delVisibles = false
    },
    submit () {
      this.$emit('handleDelete')
    }
  }
}
</script>
<style lang="less" scoped>
.title{
  color: @defaultFontColor;
}
::v-deep {
  .deleteModal {
    .ant-modal {
      border-radius: 10px;
      min-width: calc(300em / 14)!important;
      max-width: calc(360em / 14)!important;
    }
    .ant-modal-header {
      border-bottom: 1px solid rgba(21,34,50,0.2);
      .ant-modal-title {
        text-align: center;
        font-size: calc(16em / 14);
        font-family: PingFang SC, PingFang SC;
        color: #5A607F;
        font-weight: 500;
      }
    }

    .ant-modal-body {
      text-align: center;
      padding-top: calc(24em / 14)!important;
      padding-bottom: calc(32em / 14)!important;
      font-weight: 400;
      font-size: calc(14em / 14);
    }
    .ant-modal-footer {
      border-top: none;
      display: flex;
      justify-content: space-around;
      padding: 0 calc(40em / 14) calc(24em / 14);
      .ant-btn {
        width: calc(120em / 14);
        height: calc(38em / 14);
        border-radius: 5px;
      }
    }
  }
}
</style>
