/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-05-15 12:25:25
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-15 14:06:04
 * @FilePath: /mediatom-web/config/defaultConfig.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const defaultTheme = process.env.VUE_APP_ISMANAGER === 'false' ? '#3366ff' : '#6554C0'
module.exports = {
  defaultTheme
}
