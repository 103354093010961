/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-07 16:44:23
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-07 16:46:14
 * @FilePath: /mediatom-web/src/directives/clickOutSide.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import clickoutside from 'element-ui/src/utils/clickoutside'

Vue.directive('clickoutside', clickoutside)
