// 权限管理
const state = {
  // 按钮大小
  buttonSize: 0,
  // 灵敏度
  sensitivity: 0
}
const mutations = {
  SET_BUTTONSIZE: (state, buttonSize) => {
    state.buttonSize = buttonSize || 0
  },
  SET_SENSITIVITY: (state, sensitivity) => {
    state.sensitivity = sensitivity || 0
  }
}

const actions = {
  SetPermission ({ commit }, data = {}) {
    commit('SET_BUTTONSIZE', data.buttonSize)
    commit('SET_SENSITIVITY', data.sensitivity)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
