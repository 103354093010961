/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-11 16:13:48
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-15 14:06:16
 * @FilePath: /mediatom-web/src/config/defaultSettings.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 项目默认配置项
 * primaryColor - 默认主题色, 如果修改颜色不生效，请清理 localStorage
 * navTheme - sidebar theme ['dark', 'light'] 两种主题
 * colorWeak - 色盲模式
 * layout - 整体布局方式 ['sidemenu', 'topmenu'] 两种布局
 * fixedHeader - 固定 Header : boolean
 * fixSiderbar - 固定左侧菜单栏 ： boolean
 * contentWidth - 内容区布局： 流式 |  固定
 *
 * storageOptions: {} - Vue-ls 插件配置项 (localStorage/sessionStorage)
 *
 */
const { defaultTheme } = require('../../config/defaultConfig')

export default {
  navTheme: 'dark', // theme for nav menu
  primaryColor: defaultTheme, // '#F5222D', // primary color of ant design
  fixedHeader: true, // sticky header
  fixSiderbar: false, // sticky siderbar
  colorWeak: false,
  menu: {
    locale: true
  },
  title: '',
  pwa: false,
  iconfontUrl: '',
  production: process.env.NODE_ENV === 'production',
  layout: 'topmenu',
  contentWidth: 'Fluid',
  theme: 'dark',
  // fixedHeader: true,
  // fixSiderbar: false,
  hideHintAlert: false,
  hideCopyButton: false
}
