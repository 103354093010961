<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-05-10 12:10:41
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-10 14:29:59
 * @FilePath: /mediatom-web/src/components/common/IconTips/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-tooltip placement="top" :overlayStyle="overlayStyle" overlayClassName="icon-tips-container">
    <template slot="title">
      <span class="title" v-if="title">{{ title }}</span>
      <slot v-else name="title"/>
    </template>
    <span class="icon">
      <slot name="icon" />
    </span>
  </a-tooltip>
</template>

<script>
export default {
  name: 'IconTips',
  props: {
    title: {
      type: String,
      default: ''
    },
    overlayStyle: {
      type: Object,
      default: () => ({
        backgroundColor: '#ffffff00',
        borderRadius: '4px'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.icon{
  color: #fff;
  vertical-align: middle;
}
</style>

<style lang="less">
.icon-tips-container{
  .ant-tooltip-arrow::before, .ant-tooltip-inner{
    background-color: #fff!important;
    color: #333;
  }
}
</style>
