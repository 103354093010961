<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-22 18:18:37
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-24 11:37:48
 * @FilePath: /mediatom-web/src/components/common/MSelectNew/Select/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="m-select-container">
    <div
      class="m-select"
      v-if="disabled"
      :tabindex="0"
      :class="{
        'size-medium': size === 'medium',
        'size-small': size === 'small',
        'disabled': true
      }"
    >
      <!-- xxx: 请选择 -->
      <template v-if="onlyLabel">
        <div class="label">
          <span>{{ label }}</span>
          <span>：</span>
        </div>
        <div class="select-value">
          <div class="empty" v-if="!compValue">请选择</div>
          <div class="value">{{ selectedName || compValue }}</div>
        </div>
      </template>
      <!-- 请选择xxx -->
      <template v-else>
        <div class="only-placeholder" v-if="!compValue">请选择{{ label }}</div>
        <div class="only-value" v-else>{{ selectedName || compValue }}</div>
      </template>
      <!-- 箭头 -->
      <div class="arrow-icon" :class="{expend: visible, 'has-value': compValue}">
        <ArrowSvg class="svg"/>
      </div>
    </div>
    <a-popover
      v-else
      ref="popover"
      overlayClassName="cust-select-wrapper"
      trigger="click"
      placement="bottom"
      arrowPointAtCenter
      @visibleChange="visibleChange"
    >
      <div
        class="m-select"
        :tabindex="0"
        :class="{
          'size-medium': size === 'medium',
          'size-small': size === 'small',
          'clearable': clearable
        }"
      >
        <!-- xxx: 请选择 -->
        <template v-if="onlyLabel">
          <div class="label">
            <span>{{ label }}</span>
            <span>：</span>
          </div>
          <div class="select-value">
            <div class="empty" v-if="compValue === undefined || compValue === ''">请选择</div>
            <div class="value">{{ selectedName || compValue }}</div>
          </div>
        </template>
        <!-- 请选择xxx -->
        <template v-else>
          <div class="only-placeholder" v-if="!compValue">请选择{{ label }}</div>
          <div class="only-value" v-else>{{ selectedName || compValue }}</div>
        </template>
        <!-- 清空选择 -->
        <div class="close-icon" @click.stop="handleClearValue" :class="{'has-value': compValue}" v-if="clearable">
          <a-icon type="close-circle" theme="filled" />
        </div>
        <!-- 箭头 -->
        <div class="arrow-icon" :class="{expend: visible, 'has-value': compValue}">
          <ArrowSvg class="svg"/>
        </div>
      </div>
      <template slot="content">
        <div class="dropdown-box">
          <!-- <div class="dropdown-box" :style="{height: showSearch ? '300px' : '260px'}"> -->
          <div class="search-box" v-if="showSearch">
            <a-input-search ref="searchInput" v-model.trim="searchKey" :placeholder="`请输入${label}名称${searchById ? '/ID' : ''}`"/>
          </div>
          <div class="option-list">
            <!-- 选项列表 -->
            <div class="option-list-box" @scroll="handleScroll" v-if="dataListComp.length">
              <template v-for="item in dataListComp">
                <Option
                  v-bind="{
                    ...$attrs,
                    option,
                    item
                  }"
                  @handleSelectItem="handleSelectItem(item)"
                  :class="{active: '' + item[option.id] === '' + compValue}"
                  :key="item[option.id]"></Option>
              </template>
            </div>
            <!-- 空数据 -->
            <m-empty v-if="!dataListComp.length" imgHeight="100">
              <template #description>暂无数据</template>
            </m-empty>
          </div>
        </div>
      </template>
    </a-popover>
  </div>
</template>

<script>
import Option from './option.vue'
import ArrowSvg from '@/assets/icons/arrow.svg?inline'
export default {
  name: 'MSelect',
  components: { Option, ArrowSvg },
  data () {
    return {
      searchKey: '',
      visible: false,
      oldValue: undefined,
      currentNum: 20
    }
  },
  computed: {
    compValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    dataListComp () {
      let list = JSON.parse(JSON.stringify(this.dataList))
      // 未搜索
      if (!this.searchKey) {
      } else if (!this.searchById && this.searchKey && this.dataList.length) {
        // 普通搜索
        list = list.filter((item) => item[this.option.name]?.toLowerCase()?.includes(this.searchKey?.toLowerCase()))
      } else {
        // 可通过ID搜索
        list = list.filter((item) => (item[this.option.name]?.toLowerCase().includes(this.searchKey?.toLowerCase())) || (('' + item[this.option.searchId])?.toLowerCase().includes(this.searchKey?.toLowerCase())))
      }
      return list.splice(0, this.currentNum)
    },
    // 选中数据的name
    selectedName () {
      const item = this.dataList.find((item) => '' + item[this.option.id] === '' + this.value)
      return item ? item[this.option.name] : ''
    }
  },
  props: {
    onlyLabel: {
      type: Boolean,
      default: true
    },
    // 大小
    size: {
      default: 'medium',
      type: String
    },
    // label
    label: {
      default: '',
      type: String
    },
    // 绑定值
    value: {
      default: '',
      type: [String, Number]
    },
    // 数据
    dataList: {
      default: () => ([]),
      type: Array
    },
    // 配置 数据中所对应的 label value 搜索id字段
    option: {
      default: () => ({
        id: 'id',
        name: 'name',
        searchId: 'id'
      }),
      type: Object
    },
    // 是否可通过id搜索
    searchById: {
      default: false,
      type: Boolean
    },
    // 是否可清空
    clearable: {
      default: true,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    showSearch: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    handleScroll (e) {
      if (e.target.scrollTop + e.target.clientHeight + 10 >= e.target.scrollHeight) {
        this.currentNum += 10
      }
    },
    // 下拉展示变化回调
    visibleChange (e) {
      this.oldValue = this.value
      this.visible = e
      if (e) {
        this.searchKey = undefined
        this.$nextTick(() => {
          setTimeout(() => {
            this.showSearch && this.$refs.searchInput.focus()
          }, 0)
        })
      } else {
        if (this.oldValue !== this.compValue) {
          this.$emit('change', this.compValue)
        }
      }
    },
    // 清除选中
    handleClearValue () {
      this.compValue = undefined
      this.$emit('change', undefined)
    },
    handleSelectItem (item) {
      this.$refs.popover.$children[0].sVisible = false
      this.compValue = item[this.option.id]
      this.visible = false
      this.$emit('change', item[this.option.id])
    }
  }
}
</script>

<style lang="less" scoped>
@boxRadius: 5px;
@borderColor: @compBorderColor;
.m-select-container {
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
  user-select: none!important;
  .m-select {
    position: relative;
    background-color: #fff;
    width: 100%;
    // cursor: pointer;
    border: 1px solid @borderColor;
    border-radius: @boxRadius;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 @compPadding;
    transition: border 0.3s;
    &.disabled{
      color: @compDisabledColor;
      cursor: not-allowed;
      background-color: @compDisabledBc;
    }
    &.size-small {
      height: 24px;
      line-height: 24px;
    }
    &.size-medium {
      height: 36px;
      line-height: 36px;
    }
    // xxx：请选择
    .label {
      height: 100%;
      max-width: 150px;
      bottom: 1px solid;
      font-weight: 400;
      color: #5a607f;
      white-space: nowrap;
    }
    .select-value {
      height: 100%;
      flex: 1 auto;
      width: 0;
      position: relative;
      .empty{
        color: @compDisabledColor!important;
      }
      .value, .empty{
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: @compValueColor;
      }
    }
    // 请选择xxx
    .only-placeholder, .only-value{
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .only-placeholder{
      color: @compDisabledColor;
    }
    .only-value{
      color: @compValueColor;
    }
    // close  arrow
    &.clearable:hover{
      border-color: @primary-color;
      .arrow-icon.has-value{
        display: none;
      }
      .close-icon.has-value{
        display: inline-block;
      }
    }
    .arrow-icon{
      position: absolute;
      right: 12px;
      color: #ccc;
      font-size: 12px;
      display: flex;
      align-items: center;
      .svg{
        transition: transform 0.3s;
      }
      &.expend .svg{
        transform: rotate(180deg);
      }
    }
    .close-icon{
      position: absolute;
      right: 12px;
      color: #ccc;
      font-size: 12px;
      cursor: pointer;
      display: none;
    }
  }
}
// 下拉框
.dropdown-box{
  user-select: none!important;
  width: 220px;
  border-radius: @boxRadius;
  display: flex;
  flex-direction: column;
  .search-box{
    height: 45px;
    border-bottom: 1px solid @borderColor;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 6px;
  }
  .option-list{
    // height: 0;
    // max-height: 250px;
    // flex: 1 auto;
    .option-list-box{
      max-height: 250px;
      height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #dbdee5;
      }
      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #eff0f7;
      }
      // 激活样式
      .active{
        background-color: fade(@primary-color, 5%);
        color: @primary-color;
      }
    }
  }
}
</style>
