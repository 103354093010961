import request from '@/utils/request'

const api = {
  // 带app信息的app列表(之前的app报表)
  allAppList: '/app/pager',
  appList: '/app/list',
  // 人员或者账户的时候 获取app下拉框
  appListByUser: '/account/getAppListByUser',
  // place报表
  allplaceList: '/place/pager',
  // 媒体报表
  allMediaList: '/mediaLeague/pager',
  // 修改广告位
  placeUpdate: '/place/update',
  // 行业列表
  tradeList: '/app/tradeList',
  // 修改app信息
  appUpdate: '/app/update',
  // 添加app
  appAdd: '/app/add',
  // 素材模板列表
  templateList: '/creative/templateList',
  // 添加广告位
  addPlace: '/place/add',
  // 媒体联盟弹窗数据
  getPool: '/mediaLeague/pool',
  // 媒体联盟列表
  mediaLeagueList: '/mediaLeague/appList',
  // 媒体联盟状态编辑
  mediaLeagueSts: '/mediaLeague/updateSts',
  // 今日消耗及余额
  costAndRemaining: '/mediaLeague/costAndRemaining',
  // 充值
  incomePay: '/income/pay',
  // 发票抬头列表
  taxInfoList: '/invoice/taxInfoList',
  // 修改发票抬头(开票资质)
  updateTaxInfo: '/invoice/updateTaxInfo',
  // 开票时获取充值记录下拉框
  incomeList: '/income/incomeList',
  // 批量添加广告位
  batchAddPlace: '/place/batchAdd',
  // 应用列表
  appPager: '/app/appPager',
  // 获取可绑定的应用列表
  bindAppList: '/app/bindAppList',
  // 应用关联
  appBind: '/app/appBind',
  // 应用解除绑定
  appRemoveBind: '/app/appRemoveBind'
}

export default api

export function getAllAppPage (data) {
  return request({
    url: api.allAppList,
    method: 'post',
    data
  })
}
export function getAppList () {
  return request({
    url: api.appList,
    method: 'get'
  })
}
export function getAppLists (data) {
  return request({
    url: api.appListByUser,
    method: 'post',
    data
  })
}
export function getAllPlacePage (data) {
  return request({
    url: api.allplaceList,
    method: 'post',
    data
  })
}

export function getAllMediaList (data) {
  return request({
    url: api.allMediaList,
    method: 'post',
    data
  })
}

export function placeUpdate (data) {
  return request({
    url: api.placeUpdate,
    method: 'post',
    data
  })
}

export function industrySelect () {
  return request({
    url: api.tradeList,
    method: 'get'
  })
}
export function appUpdate (data) {
  return request({
    url: api.appUpdate,
    method: 'post',
    data
  })
}
export function appAdd (data) {
  return request({
    url: api.appAdd,
    method: 'post',
    data
  })
}
export function getMaterialTemplateList () {
  return request({
    url: api.templateList,
    method: 'get'
  })
}

export function addPlace (data) {
  return request({
    url: api.addPlace,
    method: 'post',
    data
  })
}

export function getPool (id) {
  return request({
    url: api.getPool + '/' + id,
    method: 'get'
  })
}

export function mediaLeagueList () {
  return request({
    url: api.mediaLeagueList,
    method: 'get'
  })
}

export function mediaLeagueSts (data) {
  return request({
    url: api.mediaLeagueSts,
    method: 'post',
    data
  })
}

export function costAndRemaining () {
  return request({
    url: api.costAndRemaining,
    method: 'get'
  })
}

export function incomePay (data) {
  return request({
    url: api.incomePay,
    method: 'post',
    data
  })
}

export function taxInfoList (data) {
  return request({
    url: api.taxInfoList,
    method: 'post',
    data
  })
}

export function updateTaxInfo (data) {
  return request({
    url: api.updateTaxInfo,
    method: 'post',
    data
  })
}

export function incomeUpload (data) {
  return request({
    url: api.updateTaxInfo,
    method: 'post',
    data
  })
}

export function incomeList (data) {
  return request({
    url: api.incomeList,
    method: 'post',
    data
  })
}

export function batchAddPlace (data) {
  return request({
    url: api.batchAddPlace,
    method: 'post',
    data
  })
}

export function getAppPager (data) {
  return request({
    url: api.appPager,
    method: 'post',
    data
  })
}

export function getBindAppList (data) {
  return request({
    url: api.bindAppList,
    method: 'post',
    data
  })
}

export function appBind (data) {
  return request({
    url: api.appBind,
    method: 'post',
    data
  })
}

export function appRemoveBind (data) {
  return request({
    url: api.appRemoveBind,
    method: 'post',
    data
  })
}
