<template>
  <div :class="wrpCls">
    <div class="right-content">
      <span class="ant-btn-link" v-if="!isManage">
        <a-button type="link" v-if="origin === 'https://app.mediatom.cn' && +isAdmin !== 2" @click="goMessage">
          <img
            style="margin: 0px 2px 4px 3px; color: white; width: 15px; height: 18px"
            src="../../assets/images/messageBell.png"
            alt=""
          />
          <a-badge :dot="showDot">消息</a-badge>
        </a-button>
      </span>
      <span class="ant-btn-link">
        <a-button
          v-if="origin === 'https://app.mediatom.cn' && +isAdmin !== 2 && !isManage"
          type="link"
          target="_blank"
          href="https://app.mediatom.cn/docs/"
        >
          <rightDocument style="margin: 0px 1px -4px 0px; width: 18px; height: 18px" />
          帮助文档
        </a-button>
      </span>
      <avatar-dropdown :menu="showMenu" :current-user="currentUser" :class="prefixCls" />
      <!-- <select-lang :class="prefixCls" /> -->
    </div>
  </div>
</template>

<script>
import rightDocument from '@/assets/icons/right-document.svg?inline'
import { mapState } from 'vuex'
import AvatarDropdown from './AvatarDropdown'
// import SelectLang from '@/components/SelectLang'

export default {
  name: 'RightContent',
  components: {
    AvatarDropdown,
    rightDocument
    // SelectLang
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action'
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    topMenu: {
      type: Boolean,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isManage: process.env.VUE_APP_ISMANAGER === 'true',
      showMenu: true,
      // 展示小红点
      showDot: false,
      currentUser: {}
    }
  },
  computed: {
    wrpCls () {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${this.isMobile || !this.topMenu ? 'light' : this.theme}`]: true
      }
    },
    ...mapState({
      origin: (state) => state.autoweb.origin,
      isAdmin: (state) => state.user.isAdmin,
      unReadMessage: (state) => state.user.unReadMessage // 未读消息
    })
  },
  watch: {
    // 未读已读判断监听
    unReadMessage: {
      handler (val) {
        if (val === '1') {
          this.showDot = true
        } else {
          this.showDot = false
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    setTimeout(() => {
      this.currentUser = {
        name: this.$store.getters.name
      }
    }, 0)
  },
  methods: {
    goMessage () {
      this.$router.push({
        path: '/accountInfo/Message'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.right-content {
  .ant-btn-link:hover {
    color: #fff !important;
  }
  .ant-btn-link {
    color: #cdd5e0 !important;
  }
}
</style>
