<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-28 17:50:40
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-01 18:06:42
 * @FilePath: /mediatom-web/src/components/common/empty/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-empty
    class="empty_box"
    :image="require('@/assets/images/no-data.png')"
    :image-style="{
      height: imgHeight
    }"
    :draggable="false"
  >
    <span slot="description" class="desc">
      <slot name="description"> </slot>
    </span>
    <slot name="operation"></slot>
  </a-empty>
</template>
<script>
export default {
  props: {
    imgHeight: {
      default: '150px',
      type: String
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="less">
.empty_box {
  box-sizing: border-box;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  img {
    -webkit-user-drag: none;
  }
  .desc{
    color: #858FA1;
    font-size: 16px;
    line-height: 37px;
    font-weight: 400;
  }
}
</style>
