<template>
  <div class="cust-select-multiple-container" style="font-size: 14px">
    <a-popover
      :overlayStyle="{ width: width }"
      v-if="!disabled"
      trigger="click"
      arrowPointAtCenter
      autoAdjustOverflow
      :destroyTooltipOnHide="true"
      overlayClassName="cust-select-wrapper"
      @visibleChange="visibleChange"
      :placement="placement"
    >
      <template #content>
        <div class="wrapper" :style="{height: hasSearch ? '340px' : '280px'}">
          <div class="search-box" v-if="hasSearch" :style="{padding: showSelectedPart ? '20px 20px 10px 20px' : '0px'}">
            <a-input-search
              ref="searchInput"
              :placeholder="`请输入${label}名称${searchById ? '/ID' : ''}`"
              v-model.trim="searchKey"
              allowClear
            />
          </div>
          <div class="contral-box">
            <div class="contral-left" :class="{'only-show': !showSelectedPart}">
              <div class="contral-top">
                <a-checkbox v-model="checkedAll" @change="handleSelectAll">全选</a-checkbox>
                <a-checkbox @change="handleSelectAdv">反选</a-checkbox>
              </div>
              <div class="show-list">
                <!-- <a-checkbox-group :value="selectData"> -->
                <div
                  class="check-group-box"
                  @scroll="handleScroll"
                >
                  <div
                    v-for="(item, index) in showList"
                    :class="{ item: true, hasId: showId, noId: !showId }"
                    :key="index"
                    class="left-show-item"
                    @click="handleChecked(item.id)"
                  >
                    <a-checkbox class="checkbox" :checked="selectData.includes(item.id)">
                    </a-checkbox>
                    <div :title="item.name" class="item-box">
                      <div class="name">
                        <template
                          v-if="hasIcon && 'os' in item"
                        ><IosSvg v-if="item.os === 0" class="svg-icon" /><AndroidSvg
                          v-else
                          class="svg-icon" /></template
                          >
                        <div class="name-text">{{ item.name }}</div>
                        <CloudSvg class="cloud-svg svg-icon" v-if="+item.apiSource === 2"/>
                      </div>
                      <div v-if="showId" class="id">
                        <div class="id-text percent-text">
                          {{ hasSearchId ? item.searchId : item.id }}
                        </div>
                        <a-tooltip placement="bottom">
                          <template slot="title">
                            <span>复制</span>
                          </template>
                          <CopySvg @click.stop.prevent="copyId(hasSearchId ? item.searchId : item.id)" class="percent-text" style="cursor: pointer; margin-left: 5px"/>
                        </a-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="contral-right" v-if="showSelectedPart">
              <div class="contral-top">
                <span>已选:{{ selectData.length }}项</span>
                <span class="clear-all" @click="handleClearAll" >清除全部</span>
              </div>
              <div class="show-list">
                <div class="show-box">
                  <div v-for="item in showBoxList" :key="item.id" class="show-item">
                    <div class="text-box">
                      <div class="name">
                        <template
                          v-if="hasIcon && 'os' in item"
                        ><IosSvg v-if="item.os === 0" class="svg-icon" /><AndroidSvg
                          v-else
                          class="svg-icon"
                        /></template>
                        {{ item.name }}</div
                        >
                      <div v-if="showId" class="id percent-text">{{ hasSearchId ? item.searchId : item.id }}</div>
                    </div>
                    <div class="close-box">
                      <a-icon @click="handleDelete(item)" class="close-icon" type="close" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        :tabindex="0"
        :class="{ select_box: true, visble: visb, middle: size === 'middle', small: size === 'small'}"
      >
        <div class="box_left">
          <span v-if="showLabel" class="left"> {{ label }}：</span>
          <div class="content" style="overflow: hidden">
            <span class="value" v-if="selectData.length > 0">{{ `已选择${selectData.length}项` }}</span>
            <template v-else>
              <span v-if="hasPlease" class="placeholder">请选择{{ showLabel ? '' : label }}</span>
              <span v-else class="placeholder">{{ showLabel ? '' : label }}</span>
            </template>
            <ArrowSvg
              class="up_icon"
              :class="{ 'isopen-middle': visb && size === 'middle', 'isopen-small': visb && size === 'small' }"
            />
            <!-- <a-icon
              class="up_icon"
              :class="{ 'isopen-middle': visb && size === 'middle', 'isopen-small': visb && size === 'small' }"
              type="down"
            /> -->
          </div>
        </div>
      </div>
    </a-popover>
    <div
      :tabindex="0"
      v-if="disabled"
      :class="{ select_box: true, visble: visb, middle: size === 'middle', small: size === 'small'}"
      class="disabled"
    >
      <div class="box_left">
        <span v-if="showLabel" class="placeholder left"> {{ label }}： </span>
        <div class="content" style="overflow: hidden">
          <span v-if="selectData.length > 0">{{ `已选择${selectData.length}项` }}</span>
          <span v-else class="placeholder">请选择{{ showLabel ? '' : label }}</span>
          <ArrowSvg
            class="up_icon"
            :class="{ 'isopen-middle': visb && size === 'middle', 'isopen-small': visb && size === 'small' }"
          />
          <!-- <a-icon
            class="up_icon"
            :class="{ 'isopen-middle': visb && size === 'middle', 'isopen-small': visb && size === 'small' }"
            type="down"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import CopySvg from '@/assets/icons/copy.svg?inline'
import ArrowSvg from '@/assets/icons/arrow.svg?inline'
import CloudSvg from '@/assets/icons/Could.svg?inline'
import { copy } from '@/utils/string'
export default {
  components: { IosSvg, AndroidSvg, CopySvg, ArrowSvg, CloudSvg },
  name: 'CustSelectMultiple',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    width: {
      default: '390px',
      type: String
    },
    // list中是否显示icon
    hasIcon: {
      default: false,
      type: Boolean
    },
    // 是否显示label
    showLabel: {
      default: true,
      type: Boolean
    },
    // 属性label
    label: {
      default: '',
      type: String
    },
    // 全部数据 必须为 name 和 id
    allData: {
      type: Array,
      default: () => []
    },
    // 是否可以通过id进行搜索
    searchById: {
      default: false,
      type: Boolean
    },
    // 是否显示id
    showId: {
      default: false,
      type: Boolean
    },
    // 绑定数据
    value: {
      default: () => [],
      type: Array
    },
    // 右侧展示部分
    showSelectedPart: {
      default: true,
      type: Boolean
    },
    canSelectAll: {
      default: true,
      type: Boolean
    },
    // 是否显示搜索栏
    hasSearch: {
      default: true,
      type: Boolean
    },
    size: {
      default: 'middle',
      type: String
    },
    // 弹窗展示位置
    placement: {
      default: 'bottom',
      type: String
    },
    hasPlease: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      // 搜索字段
      searchKey: '',
      selectData: [],
      checkedAll: false,
      current: 20,
      isLoading: false,
      visb: false,
      // changeDataFlog: false,
      filterList: [], // 用于全选的列表
      hasSearchId: false,
      oldData: []
    }
  },
  watch: {
    allData: {
      handler (val) {
        this.current = 20
        // this.changeDataFlog = true
        // 防止选中数据中有allData中不存在的选项
        const beforeLen = this.selectData.length
        this.selectData = val.filter((item) => this.selectData.includes(item.id)).map((item) => item.id)
        const currentLen = this.selectData.length
        // 触发过滤
        beforeLen > currentLen && this.$emit('filter', this.selectData)
        this.hasSearchId = !!(val.length > 0 && val[0].searchId)
      },
      immediate: true,
      deep: true
    },
    value: {
      handler (val) {
        this.selectData = val
      },
      immediate: true
    },
    indeterminate: {
      handler (val) {
        this.checkedAll = val
      }
    },
    selectData: {
      handler (newVal, oldVal) {
        let needFilter = false
        const allDataMap = this.allData.map((item) => item.id)
        newVal.forEach((item) => {
          if (!allDataMap.includes(item)) {
            needFilter = true
          }
        })
        if (needFilter) {
          this.selectData = allDataMap.filter((val) => newVal.includes(val))
        // 触发过滤
          this.$emit('filter', this.selectData)
        } else {
          this.$emit('input', newVal)
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 是否全选
    indeterminate () {
      if (this.filterList.length === 0) {
        return false
      }
      const fList = this.filterList.map((item) => item.id)
      return fList.every((item) => this.selectData.includes(item))
    },
    // 右侧showList
    showBoxList () {
      return this.allData.filter((item) => this.selectData.includes(item.id))
    },
    // 左侧showList
    showList () {
      // 满足广告源可通过id搜索
      if (this.hasSearchId) {
        this.allData.forEach((item) => {
          item.searchId = '' + item.searchId
        })
      } else {
        this.allData.forEach((item) => {
          item.id = '' + item.id
        })
      }
      const filterList = this.searchById
        ? this.allData.filter(
            (item) =>
              item.name?.toLowerCase().includes(this.searchKey?.toLowerCase()) ||
              item[this.hasSearchId ? 'searchId' : 'id']?.toLowerCase().includes(this.searchKey?.toLowerCase())
          )
        : this.allData.filter((item) => item.name?.toLowerCase().includes(this.searchKey.toLowerCase()))
      this.getfilterList([...filterList])
      return filterList.length <= this.current ? filterList : filterList.splice(0, this.current)
    }
  },
  methods: {
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    getfilterList (list) {
      this.filterList = list
    },
    // handleBlur () {
    //   this.current = 20
    // },
    handleScroll (e) {
      if (this.isLoading) return
      if (e.target.scrollTop + e.target.clientHeight + 10 >= e.target.scrollHeight) {
        this.isLoading = true
        this.current += 10
        this.isLoading = false
      }
    },
    handleChecked (id) {
      this.selectData = this.selectData.includes(id)
        ? this.selectData.filter((item) => id !== item)
        : [...this.selectData, id]
    },
    // 删除某一项
    handleDelete (item) {
      this.selectData = this.selectData.filter((child) => item.id !== child)
    },
    // 清除全部
    handleClearAll () {
      this.selectData = []
    },

    // 全选
    handleSelectAll (e) {
      let arr = e.target.checked ? [...this.selectData, ...this.filterList.map((item) => item.id)] : []
      arr = [...new Set(arr)]
      this.selectData = arr
    },
    // 反选
    handleSelectAdv () {
      this.selectData = this.filterList.filter((item) => !this.selectData.includes(item.id)).map((item) => item.id)
    },
    visibleChange (visb) {
      this.visb = visb
      if (!visb) {
        this.searchKey = ''
        const len = this.oldData.length
        if (len !== this.selectData.length || (
          len === this.selectData.length && len !== 0 &&
          this.oldData.filter((item) => this.selectData.includes(item)).length !== this.oldData.length
        )) {
          this.$emit('change', this.selectData)
        }
      } else {
        this.oldData = [...this.selectData]
        this.current = 20
        this.hasSearch && this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.searchInput.focus()
          }, 0)
        })
      }
    }
  }
}
</script>

<style lang="less">
@closeWidth: 40px;
@SearchHeight: 42px;
.cust-select-multiple-container {
  padding: 0;
  margin: 0;
  display: inline-block;
  .select_box {
    user-select: none;
    position: relative;
    border: 1px solid @compBorderColor;
    width: 100%;
    padding-left: @compPadding;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #fff;
    &.middle {
      height: @compDefaultHeight;
      line-height: @compDefaultHeight;
    }
    &.small{
      height: 24px;
      line-height: 24px;
    }
    &.disabled {
      border-color: @compBorderColor !important;
      color:  @compDisabledColor!important;
      background-color: @compDisabledBc;
      cursor: not-allowed!important;
    }
    &:hover {
      border: 1px solid @primary-color;
    }
    &:focus,
    &.visble {
      border: 1px solid @primary-color;
      outline: none;
    }
    .box_left {
      flex: 1, 1;
      width: 100%;
      display: flex;
      align-items: center;
      .placeholder{
        color: @compDisabledColor;
      }
      .left {
        display: inline-block;
        max-width: 120px;
        min-width: 35px;
        text-align: center;
        padding-right: 0px;
        height: 100%;
        color: #5a607f;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 400;
      }
      .content {
        max-width: 70%;
        white-space: nowrap;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .value{
          color: @compValueColor;
        }
        .up_icon {
          position: absolute;
          right: 12px;
          font-size: 12px;
          color: #b2b2b2;
          transition: transform 0.2s;
          &.isopen-middle {
            transform: rotate(180deg);
          }
          &.isopen-small {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
@borderstyle:1px solid @compBorderColor;
@closeBoxWidth: 20px;
.cust-select-wrapper {
  display: inline-block;
}
.cust-select-wrapper .wrapper {
  user-select: none;
  height: 340px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  // 搜索区域
  .search-box{
    width: 100%;
  }
  .contral-box{
    height: 0;
    flex: 1 auto;
    display: flex;
    position: relative;
    .contral-right .show-list{
      border-left: @borderstyle;
    }
    .contral-left, .contral-right{
      width: 50%;
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;
      // 仅显示左侧
      &.only-show{
        width: 100%;
        .contral-top{
          padding-bottom: 5px;
        }
      }
      // 两侧操作项 全选 反选 清除
      .contral-top{
        padding: 10px 15px 14px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: @borderstyle;
        color: @defaultFontColor;
        .clear-all{
          color: @assisColor;
          cursor: pointer;
        }
      }
      .show-list{
        flex: 1 auto;
        position: relative;
        height: 0;
        .check-group-box{
          width: 100%;
          height: 100%;
          padding: 0 10px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 4px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: #dbdee5;
          }
          &::-webkit-scrollbar-track {
            border-radius: 4px;
            background: #eff0f7;
          }
          .left-show-item{
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 5px ;
            margin: 5px 0;
            border-radius: 5px;
            position: relative;
            width: 100%;
            &:hover{
              background-color: fade(#000, 5%);
            }
            .checkbox{
              margin-right: 10px;
            }
            .item-box{
              width: 0;
              flex: 1 auto;
              position: relative;
              display: flex;
              flex-direction: column;
              .name{
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                .svg-icon{
                  flex: 1 auto;
                  height: 11px;
                  min-width: 11px;
                  max-width: 11px;
                  margin-right: 3px;
                }
                .name-text{
                  flex: 1 auto;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  color: @defaultFontColor;
                }
                .cloud-svg{
                  transform: translateX(-2px);
                }
              }
              .id{
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                align-items: center;
                padding-right: 5px;
                line-height: 17px;
                .id-text{
                  max-width: 85%;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }
            }
          }
        }
        .show-box{
          height: 100%;
          width: 100%;
          padding: 0 10px;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          &::-webkit-scrollbar {
            width: 4px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: #dbdee5;
          }
          &::-webkit-scrollbar-track {
            border-radius: 4px;
            background: #eff0f7;
          }
          .show-item{
            border-radius: 5px;
            padding: 3px;
            width: 100%;
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            align-items: center;
            margin: 3px 0;
            .text-box{
              display: flex;
              flex-direction: column;
              padding: 0 5px;
              width: 85%;
              .name{
                width: 100%;
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: @defaultFontColor;
                .svg-icon{
                  height: 12px;
                  width: 12px;
                }
              }
              .id{
                font-size: 12px;
                line-height: 17px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .close-box{
              font-size: 12px;
              line-height: 20px;
              cursor: pointer;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
